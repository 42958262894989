import React, { useState, useCallback, useRef } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import { searchPlayers } from "../services/player.service";

const EditPlayerModal = ({
  show,
  onHide,
  editingPlayer,
  editingPosition,
  POSITION_MAP,
  selectedWeek,
  getAccessTokenSilently,
  isPlayerOnOtherRoster,
  onSavePlayer,
  formatCurrency,
  roster,
  salaryCap,
  weeks,             // Add this prop
  rosters,           // Add this prop
  selectedTeam,      // Add this prop
}) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [playerSearch, setPlayerSearch] = useState("");
  const [playerOptions, setPlayerOptions] = useState([]);
  const [showPlayerOptions, setShowPlayerOptions] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [error, setError] = useState(null);

  const selectedPlayerPosition = selectedPlayer
    ? POSITION_MAP[editingPosition] === "FLEX"
      ? selectedPlayer.position
      : POSITION_MAP[editingPosition]
    : POSITION_MAP[editingPosition];

  const playerSearchRef = useRef(null);

  const handlePlayerSearch = useCallback(
    async (searchTerm) => {
      if (searchTerm.length < 2) {
        setPlayerOptions([]);
        setShowPlayerOptions(false);
        return;
      }
      setModalLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const isFlexPosition =
          editingPosition === "FLEX1" || editingPosition === "FLEX2";
        const positionType = POSITION_MAP[editingPosition];

        const { data, error } = await searchPlayers(token, searchTerm);
        if (error) {
          throw new Error(error);
        }

        const rosterTotalSalary = roster.reduce((total, player) => {
          if (editingPlayer && player.playerID === editingPlayer.playerID) {
            return total;
          }
          return total + (Number(player.salary) || 0);
        }, 0);

        const availableCap = salaryCap
          ? Number(salaryCap) - rosterTotalSalary
          : 0;

        // Get the previous week's roster for the current team
        const weekIndex = selectedWeek ? weeks.indexOf(selectedWeek) : -1;
        const previousWeek = weekIndex > 0 ? weeks[weekIndex - 1] : null;
        const ourPreviousWeekRoster = previousWeek && rosters && selectedTeam 
          ? rosters[selectedTeam]?.weeks[previousWeek] || []
          : [];

        const filteredPlayers = data.filter((player) => {
          const playerSalary = Number(player.salary) || 0;
          const wouldFitUnderCap = playerSalary <= availableCap;
          
          // Check if player was on our roster this week or last week
          const wasOnOurRosterThisWeek = roster.some(p => p.playerID === player.playerID);
          const wasOnOurRosterLastWeek = ourPreviousWeekRoster.some(p => p.playerID === player.playerID);
          
          // Only check other rosters if the player wasn't on our roster in either week
          const shouldCheckOtherRosters = !wasOnOurRosterThisWeek && !wasOnOurRosterLastWeek;
          const isOnOtherRoster = shouldCheckOtherRosters && isPlayerOnOtherRoster(player.playerID);

          let positionMatch = false;
          if (isFlexPosition) {
            const validPositions = ["RB", "WR", "TE", "D"];
            positionMatch = validPositions.includes(player.position);
          } else {
            positionMatch = player.position === positionType;
          }

          // A player should be available if:
          // 1. They're on our current roster
          // OR
          // 2. They were on our roster last week
          // OR
          // 3. They're not on another roster and fit under cap
          const isAvailableToAdd = wasOnOurRosterThisWeek || 
                                 wasOnOurRosterLastWeek ||
                                 (!isOnOtherRoster && wouldFitUnderCap);

          console.log(`Full evaluation for ${player.name}:`, {
            playerID: player.playerID,
            position: player.position,
            salary: playerSalary,
            wasOnOurRosterThisWeek,
            wasOnOurRosterLastWeek,
            shouldCheckOtherRosters,
            isOnOtherRoster,
            wouldFitUnderCap,
            positionMatch,
            isAvailableToAdd,
            finalResult: positionMatch && isAvailableToAdd
          });

          return positionMatch && isAvailableToAdd;
        });

        if (filteredPlayers.length === 0) {
          const errorMessage =
            data.length > 0
              ? `No players found within available cap space (${formatCurrency(
                  availableCap
                )})`
              : "No matching players found";
          setError(errorMessage);
        }

        const formattedPlayers = filteredPlayers.map((player) => ({
          ...player,
          displayName: `${player.name} (${player.position} - ${
            player.teamAbv
          }) - ${formatCurrency(player.salary)}`,
          wasOnOurRosterThisWeek: roster.some(p => p.playerID === player.playerID),
          wasOnOurRosterLastWeek: ourPreviousWeekRoster.some(p => p.playerID === player.playerID)
        }));

        setPlayerOptions(formattedPlayers);
        setShowPlayerOptions(formattedPlayers.length > 0);
      } catch (error) {
        console.error("Search error:", error);
        setError(`Failed to search players: ${error.message}`);
      } finally {
        setModalLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      editingPosition,
      POSITION_MAP,
      isPlayerOnOtherRoster,
      roster,
      editingPlayer,
      formatCurrency,
      salaryCap,
      weeks,
      selectedWeek,
      rosters,
      selectedTeam
    ]
  );

  const handlePlayerSelect = useCallback((player) => {
    setSelectedPlayer(player);
    setPlayerSearch(player.displayName);
    setShowPlayerOptions(false);
  }, []);

  const handleClose = useCallback(() => {
    setPlayerSearch("");
    setPlayerOptions([]);
    setShowPlayerOptions(false);
    setSelectedPlayer(null);
    setError(null);
    onHide();
  }, [onHide]);

  const handleSave = useCallback(() => {
    onSavePlayer(selectedPlayer);
    handleClose();
  }, [selectedPlayer, onSavePlayer, handleClose]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {editingPosition} Position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        
        {editingPlayer && (
          <div className="mb-3">
            <h6>Current Roster Player:</h6>
            <div className="player-info p-3 bg-light rounded">
              <strong>Name:</strong> {editingPlayer.player_name}
              <br />
              <strong>Team:</strong> {editingPlayer.teamAbv}
              <br />
              <strong>Position:</strong> {editingPosition}
              <br />
              <strong>Salary:</strong> {formatCurrency(editingPlayer.salary)}
            </div>
          </div>
        )}

        <div className="mb-3">
          <h6>Available Cap Space:</h6>
          <div className="cap-info p-3 bg-light rounded">
            {formatCurrency(
              (Number(salaryCap) - roster.reduce((total, player) => {
                if (editingPlayer && player.playerID === editingPlayer.playerID) {
                  return total;
                }
                return total + (Number(player.salary) || 0);
              }, 0))
            )}
          </div>
        </div>

        <Form.Group ref={playerSearchRef}>
  <Form.Label>
    <h6>Search for new {POSITION_MAP[editingPosition]} player:</h6>
    {modalLoading && (
      <small className="text-muted">Loading available players...</small>
    )}
  </Form.Label>
  <Form.Control
    type="text"
    value={playerSearch}
    onChange={(e) => {
      setPlayerSearch(e.target.value);
      handlePlayerSearch(e.target.value);
    }}
    placeholder={`Search ${POSITION_MAP[editingPosition]} players...`}
    disabled={modalLoading}
    required
  />
  {showPlayerOptions && (
    <ListGroup
      className="position-absolute w-100"
      style={{ zIndex: 1000 }}
    >
      {playerOptions.length > 0 ? (
        playerOptions.map((player, index) => (
          <ListGroup.Item
            key={index}
            action
            onClick={() => handlePlayerSelect(player)}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <strong>{player.name}</strong> ({player.position} -{" "}
              {player.teamAbv})
            </span>
            <span className="text-success">
              {formatCurrency(player.salary)}
              {player.wasOnOurRosterThisWeek && (
                <span className="ms-2 badge bg-info">Current Roster</span>
              )}
              {!player.wasOnOurRosterThisWeek && player.wasOnOurRosterLastWeek && (
                <span className="ms-2 badge bg-warning">Previous Week</span>
              )}
            </span>
          </ListGroup.Item>
        ))
      ) : (
        <ListGroup.Item>
          {error || "No matching players found"}
        </ListGroup.Item>
      )}
    </ListGroup>
  )}
</Form.Group>

        {selectedPlayer && (
          <div className="mt-3">
            <h6>Selected Player to Add:</h6>
            <div className="player-info p-3 bg-light rounded">
              <strong>Name:</strong> {selectedPlayer.playerName}
              <br />
              <strong>Team:</strong> {selectedPlayer.teamAbv}
              <br />
              <strong>Position:</strong> {selectedPlayerPosition}
              <br />
              {POSITION_MAP[editingPosition] === "FLEX" && (
                <>
                  <strong>Roster Spot:</strong> {editingPosition}
                  <br />
                </>
              )}
              <strong>Salary:</strong> {formatCurrency(selectedPlayer.salary)}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!selectedPlayer || modalLoading}
        >
          {modalLoading ? "Loading..." : "Confirm Roster Change"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPlayerModal;