// Section 1: Imports and Initial Setup
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Alert,
  Spinner,
  Form,
  Table,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getAllPlayersFantasyPoints,
  getWeeklyPlayerStats,
} from "../services/fantasyPoints.service";
import { getGameWeeks, getCurrentWeek } from "../services/schedule.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { compareWeeks, sortWeeks } from "../components/WeekUtils";

export const PlayerFantasyPointsPage = () => {
  // Theme and font size context
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently } = useAuth0();

  // Header font size calculation
  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  // Section 2: State Declarations
  // Data states
  const [playersData, setPlayersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return window.innerWidth < 768 ? 10 : 20;
  });

  // Filter states
  const [playerNameFilter, setPlayerNameFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("All");
  const [teamFilter, setTeamFilter] = useState("All Teams");
  const [tuffTeamFilter, setTuffTeamFilter] = useState("All Teams");

  // Sort states
  const [sortColumn, setSortColumn] = useState("fantasyPoints");
  const [sortDirection, setSortDirection] = useState("desc");

  // Week selection states
  const [availableWeeks, setAvailableWeeks] = useState(["Total"]);
  const [selectedWeek, setSelectedWeek] = useState("Total");
  const [currentWeek, setCurrentWeek] = useState("");

  // Team lists states
  const [totalTeams, setTotalTeams] = useState(["All Teams"]);
  const [weeklyTeams, setWeeklyTeams] = useState(["All Teams"]);

  // Memoized values
  const uniqueTeams = useMemo(() => {
    return selectedWeek === "Total" ? totalTeams : weeklyTeams;
  }, [selectedWeek, totalTeams, weeklyTeams]);

  const getUniqueTuffTeams = useMemo(() => {
    const teams = new Set();
    playersData.forEach((player) => {
      if (player.tuffTeams) {
        player.tuffTeams.forEach((team) => teams.add(team));
      }
    });
    // Return array with "All Teams", "Free Agents", and then the sorted team names
    return ["All Teams", "Free Agents", ...Array.from(teams).sort()];
  }, [playersData]);

  // Section 3: Data Fetching Functions
  const fetchPlayersData = useCallback(async (accessToken, week) => {
    try {
      if (week === "Total") {
        const response = await getAllPlayersFantasyPoints(accessToken);

        const teams = [
          "All Teams",
          ...new Set(
            response.data.map((player) => player.teamAbv).filter(Boolean)
          ),
        ].sort((a, b) => {
          if (a === "All Teams") return -1;
          if (b === "All Teams") return 1;
          return a.localeCompare(b);
        });

        setTotalTeams(teams);
        return response.data;
      } else {
        const response = await getWeeklyPlayerStats(accessToken, week);
        console.log("Weekly response:", response);

        // Verify the response structure and extract data
        if (!response || !response.data || !Array.isArray(response.data)) {
          // If response.data is not an array, check if it has a data property
          if (
            response.data &&
            response.data.data &&
            Array.isArray(response.data.data)
          ) {
            const weeklyData = response.data.data;
            console.log(
              "Weekly data extracted from nested structure:",
              weeklyData[0]
            );

            // Extract and sort unique teams for weekly view
            const teams = [
              "All Teams",
              ...new Set(
                weeklyData.map((player) => player.team).filter(Boolean)
              ),
            ].sort((a, b) => {
              if (a === "All Teams") return -1;
              if (b === "All Teams") return 1;
              return a.localeCompare(b);
            });

            setWeeklyTeams(teams);
            return weeklyData;
          } else {
            console.error("Unexpected data structure:", response.data);
            throw new Error("Weekly data is not in expected format");
          }
        }

        // Extract and sort unique teams for weekly view
        const teams = [
          "All Teams",
          ...new Set(
            response.data.map((player) => player.team).filter(Boolean)
          ),
        ].sort((a, b) => {
          if (a === "All Teams") return -1;
          if (b === "All Teams") return 1;
          return a.localeCompare(b);
        });

        setWeeklyTeams(teams);
        return response.data;
      }
    } catch (err) {
      console.error("Error fetching players data:", err);
      console.error("Full error details:", err);
      setError(`Error fetching fantasy points: ${err.message}`);
      return [];
    }
  }, []);

  // Week change handler
  const handleWeekChange = useCallback(
    async (newWeek) => {
      setSelectedWeek(newWeek);
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const playersDataResponse = await fetchPlayersData(
          accessToken,
          newWeek
        );

        if (!Array.isArray(playersDataResponse)) {
          throw new Error("Invalid data format received");
        }

        console.log("Frontend weekly data received:", playersDataResponse[0]);
        setPlayersData(playersDataResponse);
        setFilteredData(playersDataResponse);
      } catch (err) {
        console.error("Error in handleWeekChange:", err);
        setError(
          `Error fetching fantasy points for ${newWeek}: ${err.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, fetchPlayersData]
  );

  // Sort handler
  const handleSort = useCallback((column) => {
    setSortColumn(column);
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  }, []);

  // Section 4: useEffect Hooks
  // Initial data loading
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const [weeksResponse, currentWeekResponse] = await Promise.all([
          getGameWeeks(accessToken),
          getCurrentWeek(accessToken),
        ]);

        if (weeksResponse.error) throw new Error(weeksResponse.error);
        if (currentWeekResponse.error)
          throw new Error(currentWeekResponse.error);

        const currentWeekData = currentWeekResponse.data;
        setCurrentWeek(currentWeekData);

        // Filter and sort available weeks
        const filteredWeeks = weeksResponse.data
          .filter((week) => {
            const weekNumber = parseInt(week.replace("Week ", ""));
            return weekNumber >= 1 && weekNumber <= 17;
          })
          .filter((week) => compareWeeks(week, currentWeekData) <= 0);

        const sortedWeeks = ["Total", ...sortWeeks(filteredWeeks)];
        setAvailableWeeks(sortedWeeks);

        // Fetch initial players data
        const playersDataResponse = await fetchPlayersData(
          accessToken,
          "Total"
        );
        setPlayersData(playersDataResponse);
        setFilteredData(playersDataResponse);
      } catch (err) {
        console.error("Error in fetchInitialData:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [getAccessTokenSilently, fetchPlayersData]);

  // Data filtering and sorting effect
  useEffect(() => {
    console.log("Starting filtering with data:", playersData[0]);
    const filtered = playersData.filter((player) => {
      const positionMatch =
        positionFilter === "All" ||
        (positionFilter === "DEF"
          ? selectedWeek === "Total"
            ? player.position === "Team Defense"
            : player.position === "DEF"
          : player.position === positionFilter);

      const teamMatch =
        teamFilter === "All Teams" ||
        (selectedWeek === "Total"
          ? player.teamAbv === teamFilter
          : player.team === teamFilter);

      const tuffTeamMatch =
        tuffTeamFilter === "All Teams"
          ? true
          : tuffTeamFilter === "Free Agents"
          ? !player.tuffTeams || player.tuffTeams.length === 0
          : player.tuffTeams && player.tuffTeams.includes(tuffTeamFilter);

      const nameMatch =
        player &&
        player.playerName &&
        player.playerName
          .toLowerCase()
          .includes(playerNameFilter.toLowerCase());

      return positionMatch && teamMatch && tuffTeamMatch && nameMatch;
    });

    const sorted = filtered.sort((a, b) => {
      let aValue, bValue;

      switch (sortColumn) {
        case "playerName":
          aValue = a.playerName;
          bValue = b.playerName;
          break;
        case "position":
          aValue = a.position;
          bValue = b.position;
          break;
        case "team":
          aValue = selectedWeek === "Total" ? a.teamAbv : a.team;
          bValue = selectedWeek === "Total" ? b.teamAbv : b.team;
          break;
        case "tuffTeam":
          // Sort by first TUFF team name, or 'Free Agent' if no teams
          aValue =
            a.tuffTeams && a.tuffTeams.length > 0
              ? a.tuffTeams[0]
              : "Free Agent";
          bValue =
            b.tuffTeams && b.tuffTeams.length > 0
              ? b.tuffTeams[0]
              : "Free Agent";
          break;
        default:
          aValue = a.totalFantasyPoints;
          bValue = b.totalFantasyPoints;
      }

      if (typeof aValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
    });

    console.log("After filtering/sorting:", filtered[0]);
    setFilteredData(sorted);
    setCurrentPage(1);
  }, [
    playersData,
    playerNameFilter,
    teamFilter,
    tuffTeamFilter,
    positionFilter,
    sortColumn,
    sortDirection,
    selectedWeek,
  ]);

  // Section 5: Pagination and Table Rendering
  // Pagination calculations
  const totalPages =
    itemsPerPage === "all" ? 1 : Math.ceil(filteredData.length / itemsPerPage);

  const currentData =
    itemsPerPage === "all"
      ? filteredData
      : filteredData.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  // Pagination renderer
  const renderPagination = () => {
    if (itemsPerPage === "all") return null;

    let items = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // First and Prev buttons
    items.push(
      <Pagination.First
        key="first"
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
    );
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
        disabled={currentPage === 1}
      />
    );

    // Start ellipsis
    if (startPage > 1) {
      items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }

    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // End ellipsis
    if (endPage < totalPages) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }

    // Next and Last buttons
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => setCurrentPage((prev) => Math.min(totalPages, prev + 1))}
        disabled={currentPage === totalPages}
      />
    );
    items.push(
      <Pagination.Last
        key="last"
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return (
      <Pagination className="flex-wrap justify-content-center">
        {items}
      </Pagination>
    );
  };

  // Table renderer
  const renderTable = () => (
    <div className="player-points-table-container">
      <Table
        bordered
        striped
        hover
        size="sm"
        variant={theme}
        className="align-middle"
      >
        {console.log("Rendering table with data:", currentData[0])}
        <thead>
          <tr>
            <th className="player-name-column" onClick={() => handleSort("playerName")}>
              Player Name{' '}
              {sortColumn === "playerName" && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
            <th className="player-position-column" onClick={() => handleSort("position")}>
              Position{' '}
              {sortColumn === "position" && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
            <th className="player-team-column" onClick={() => handleSort("team")}>
              Team{' '}
              {sortColumn === "team" && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
            <th className="player-points-column" onClick={() => handleSort("totalFantasyPoints")}>
              Fantasy Points{" "}
              {selectedWeek !== "Total" ? `(${selectedWeek})` : "(Total)"}{' '}
              {sortColumn === "totalFantasyPoints" && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
            <th className="player-tuff-team-column" onClick={() => handleSort("tuffTeam")}>
              TUFF Team(s){' '}
              {sortColumn === "tuffTeam" && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((player, index) => (
            <tr key={index}>
              <td className="player-name-column">{player.playerName}</td>
              <td className="player-position-column">
                {selectedWeek === "Total" && player.position === "Team Defense"
                  ? "DEF"
                  : player.position}
              </td>
              <td className="player-team-column">
                {selectedWeek === "Total" ? player.teamAbv : player.team}
              </td>
              <td className="player-points-column">{player.totalFantasyPoints}</td>
              <td className="player-tuff-team-column" title={player.tuffTeams && player.tuffTeams.join(", ")}>
                {player.tuffTeamAbbreviations && player.tuffTeamAbbreviations.length > 0
                  ? player.tuffTeamAbbreviations.join(", ")
                  : "Free Agent"}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  // Section 6: Form Controls and Main Return
  // Loading spinner renderer
  if (loading) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      <div className="player-points-wrapper">
        <br />
        <h4 style={{ fontSize: headerFontSize }} className="text-center">
          Player Fantasy Points
        </h4>
        <br />
        {error && <Alert variant="danger">{error}</Alert>}

        <div className="player-points-content">
          {/* Filter Controls */}
          <div className="player-points-form-container">
            <Row className="justify-content-center">
              <Col md={11}>
                <Form>
                  <Row className="justify-content-center align-items-end">
                    {/* Player Name Filter */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Player Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Search by player name"
                          value={playerNameFilter}
                          onChange={(e) => setPlayerNameFilter(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    {/* Position Filter */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Position</Form.Label>
                        <Form.Select
                          value={positionFilter}
                          onChange={(e) => setPositionFilter(e.target.value)}
                        >
                          <option value="All">All Positions</option>
                          <option value="QB">Quarterback</option>
                          <option value="RB">Running Back</option>
                          <option value="WR">Wide Receiver</option>
                          <option value="TE">Tight End</option>
                          <option value="DEF">DEF</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {/* NFL Team Filter */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>NFL Team</Form.Label>
                        <Form.Select
                          value={teamFilter}
                          onChange={(e) => setTeamFilter(e.target.value)}
                        >
                          {uniqueTeams.map((team) => (
                            <option key={team} value={team}>
                              {team}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {/* TUFF Team Filter */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>TUFF Team</Form.Label>
                        <Form.Select
                          value={tuffTeamFilter}
                          onChange={(e) => setTuffTeamFilter(e.target.value)}
                        >
                          {getUniqueTuffTeams.map((team) => (
                            <option key={team} value={team}>
                              {team}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {/* Week Selection */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Week</Form.Label>
                        <Form.Select
                          value={selectedWeek}
                          onChange={(e) => handleWeekChange(e.target.value)}
                        >
                          {availableWeeks.map((week) => (
                            <option key={week} value={week}>
                              {week === currentWeek
                                ? `${week} (Current)`
                                : week}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {/* Items Per Page Selection */}
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Players per Page</Form.Label>
                        <Form.Select
                          value={itemsPerPage}
                          onChange={(e) => {
                            const value = e.target.value;
                            setItemsPerPage(
                              value === "all" ? "all" : Number(value)
                            );
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10}>10 per page</option>
                          <option value={20}>20 per page</option>
                          <option value={50}>50 per page</option>
                          <option value={100}>100 per page</option>
                          <option value="all">Show All</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>

          {/* Table */}
          <Row className="justify-content-center">
            <Col md={11}>{renderTable()}</Col>
          </Row>

          {/* Pagination */}
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="d-flex justify-content-center">
                {renderPagination()}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default PlayerFantasyPointsPage;
