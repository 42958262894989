export const compareWeeks = (week1, week2) => {
  const getWeekNumber = (week) => {
    const match = week.match(/Week (\d+)/);
    return match ? parseInt(match[1]) : 0; // Returns 0 if no match
  };
  
  const num1 = getWeekNumber(week1);
  const num2 = getWeekNumber(week2);
  
  return num1 - num2;
};

export const sortWeeks = (weeks) => {
  return [...weeks].sort(compareWeeks);
};

export const getWeekString = (week) => {
  if (typeof week === "string") {
    return week;
  }
  if (typeof week === "object" && week !== null && "currentWeek" in week) {
    return week.currentWeek;
  }
  console.warn("Unexpected week format:", week);
  return "";
};

export const getWeekName = (weekNumber) => {
  if (weekNumber && weekNumber !== "") {
    return `Week ${weekNumber}`;
  }
  return "Select a Week";
};
