import { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useCommissionerCheck = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isCommissioner, setIsCommissioner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommissionerStatus = async () => {
      if (!user) return; // Wait for the user to be available

      try {
        const token = await getAccessTokenSilently(); // Get the access token
        const response = await fetch(`${apiServerUrl}/api//commissioner/check-commissioner`, { // Use the appropriate endpoint
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}` // Include the token in the request
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch commissioner status');
        }

        const data = await response.json();
        setIsCommissioner(data.isCommissioner); // Set the state based on API response
      } catch (error) {
        console.error('Error fetching commissioner status:', error);
        setError('Failed to load commissioner status');
      } finally {
        setLoading(false);
      }
    };

    fetchCommissionerStatus();
  }, [user, getAccessTokenSilently]); // Dependency array ensures effect runs when user or token retrieval changes

  return { isCommissioner, loading, error }; // Return the commission status, loading state, and error state
};
