import { callExternalApi } from "./external-api.service";
const apiServerUrl = process.env.REACT_APP_SCHEDULE_API;

export const getGameWeeks = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/gameweeks`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: Array.isArray(data) ? data : [],
    error,
  };
};

export const getScheduleForWeek = async (accessToken, week) => {
  const config = {
    url: `${apiServerUrl}/`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  console.log(`Calling API for full schedule: ${config.url}`);
  try {
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error("API error:", error);
      return { data: null, error };
    }
    
    if (!Array.isArray(data)) {
      console.error("Unexpected data format received from API");
      return { data: null, error: "Unexpected data format received from API" };
    }
    
    // Filter the schedule for the specific week
    const weekSchedule = data.filter(game => game.gameWeek === week);
    console.log(`Schedule data for week ${week}:`, weekSchedule);
    
    return { data: weekSchedule.length > 0 ? weekSchedule : null, error: null };
  } catch (error) {
    console.error("Exception in getScheduleForWeek:", error);
    return { data: null, error: error.message };
  }
};

export const getTeamStatusForWeek = async (accessToken, week) => {
  const encodedWeek = encodeURIComponent(week);
  const config = {
    url: `${apiServerUrl}/team-status/${encodedWeek}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data?.teamStatus || {},
    error,
  };
};

export const getCurrentWeek = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/current-week`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  console.log(`Calling API for current week: ${config.url}`);
  try {
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error("API error:", error);
      return { data: null, error };
    }
    console.log("Current week data received:", data);
    
    // Ensure we're returning the currentWeek string directly
    return { data: data.currentWeek || null, error: null };
  } catch (error) {
    console.error("Exception in getCurrentWeek:", error);
    return { data: null, error: error.message };
  }
};