import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Alert, Spinner, Form, Table, Pagination } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getWeeklyPlayerStats } from '../services/fantasyPoints.service';
import { useTheme } from '../contexts/ThemeContext';
import { useFontSize } from '../contexts/FontSizeContext';

export const WeeklyPlayerStatsPage = () => {
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently } = useAuth0();
  const [playerStats, setPlayerStats] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('1');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [positionFilter, setPositionFilter] = useState('All');
  const playersPerPage = 20;

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  const fetchPlayerStats = useCallback(async (week) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getWeeklyPlayerStats(accessToken, week);
      console.log('Fetched player stats:', data);
      setPlayerStats(data);
    } catch (err) {
      console.error('Error fetching player stats:', err);
      setError(`Error fetching player stats: ${err.message}`);
      setPlayerStats([]);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchPlayerStats(selectedWeek);
  }, [fetchPlayerStats, selectedWeek]);

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
    setCurrentPage(1);
  };

  const handlePositionFilterChange = (event) => {
    setPositionFilter(event.target.value);
    setCurrentPage(1);
  };

  const filteredPlayers = useMemo(() => {
    return playerStats.filter(player => 
      positionFilter === 'All' || player.position === positionFilter
    );
  }, [playerStats, positionFilter]);

  const totalPages = Math.ceil(filteredPlayers.length / playersPerPage);

  const currentPlayers = useMemo(() => {
    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    return filteredPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer);
  }, [filteredPlayers, currentPage]);

  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  const renderStatsTable = () => (
    <Table striped bordered hover responsive size="sm" variant={theme}>
      <thead>
        <tr>
          <th>Player Name</th>
          <th>Position</th>
          <th>Team</th>
          <th>Pass Yds</th>
          <th>Pass Yds Pts</th>
          <th>Pass TDs</th>
          <th>Pass TDs Pts</th>
          <th>Rush Yds</th>
          <th>Rush Yds Pts</th>
          <th>Rush TDs</th>
          <th>Rush TDs Pts</th>
          <th>Receptions</th>
          <th>Rec Pts</th>
          <th>Rec Yds</th>
          <th>Rec Yds Pts</th>
          <th>Rec TDs</th>
          <th>Rec TDs Pts</th>
          <th>Total Pts</th>
        </tr>
      </thead>
      <tbody>
        {currentPlayers.map((player, index) => (
          <tr key={index}>
            <td>{player.playerName}</td>
            <td>{player.position}</td>
            <td>{player.team}</td>
            <td>{player.passing.yards}</td>
            <td>{player.passing.yardsPoints}</td>
            <td>{player.passing.touchdowns}</td>
            <td>{player.passing.touchdownsPoints}</td>
            <td>{player.rushing.yards}</td>
            <td>{player.rushing.yardsPoints}</td>
            <td>{player.rushing.touchdowns}</td>
            <td>{player.rushing.touchdownsPoints}</td>
            <td>{player.receiving.receptions}</td>
            <td>{player.receiving.receptionsPoints}</td>
            <td>{player.receiving.yards}</td>
            <td>{player.receiving.yardsPoints}</td>
            <td>{player.receiving.touchdowns}</td>
            <td>{player.receiving.touchdownsPoints}</td>
            <td>{player.totalFantasyPoints}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Container fluid>
      <br />
      <h4 style={{ fontSize: headerFontSize }}>Weekly Player Stats and Fantasy Points</h4>
      <br />
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3">
        <Form.Label>Select Week:</Form.Label>
        <Form.Select value={selectedWeek} onChange={handleWeekChange}>
          {Array.from({ length: 17 }, (_, i) => i + 1).map((week) => (
            <option key={week} value={week.toString()}>
              Week {week}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Filter by Position:</Form.Label>
        <Form.Select value={positionFilter} onChange={handlePositionFilterChange}>
          <option value="All">All Positions</option>
          <option value="QB">Quarterback</option>
          <option value="RB">Running Back</option>
          <option value="WR">Wide Receiver</option>
          <option value="TE">Tight End</option>
          <option value="DEF">Defense</option>
        </Form.Select>
      </Form.Group>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : filteredPlayers.length > 0 ? (
        <>
          {renderStatsTable()}
          <div className="d-flex justify-content-center">
            {renderPagination()}
          </div>
        </>
      ) : (
        <Alert variant="info">No player stats available for this week and position.</Alert>
      )}
    </Container>
  );
};

export default WeeklyPlayerStatsPage;