import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useCallback } from "react";
import { getProtectedResource } from "../services/nfl-news.service";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import SSEManager from "./SSEManager";

export const NFLNewsFooter = withLDConsumer()(({ flags }) => {
  const [nflNews, setNFLNews] = useState([]);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const logDebug = useCallback(
    (message, ...args) => {
      if (flags?.frontendConsoleLogging) {
        console.log(message, ...args);
      }
    },
    [flags?.frontendConsoleLogging]
  );

  const handleSSEMessage = useCallback(
    (data) => {
      logDebug("handleSSEMessage called with data:", data);
      if (data.type === "nflnews" && Array.isArray(data.news)) {
        logDebug("Updating news state with:", data.news);
        setNFLNews(data.news);
      } else {
        logDebug("Received unexpected SSE message format:", data);
      }
    },
    [logDebug]
  );

  const fetchInitialData = useCallback(async (accessToken) => {
    try {
      logDebug("Fetching initial data");
      const response = await getProtectedResource(accessToken, "/api/nflnews/latest");
      logDebug("Initial data received:", response.data);
      if (Array.isArray(response.data)) {
        setNFLNews(response.data);
      } else {
        throw new Error("Received invalid data format");
      }
    } catch (err) {
      console.error("Error fetching initial data:", err);
      setError("Failed to load news. Please try again later.");
    }
  }, [logDebug]);

  useEffect(() => {
    logDebug("NFLNewsFooter component mounted");
    let isActive = true;

    const setupSSE = async () => {
      logDebug("setupSSE function called");
      try {
        logDebug("Attempting to get access token");
        const accessToken = await getAccessTokenSilently();
        logDebug("Access token obtained");

        if (isActive) {
          SSEManager.setAccessToken(accessToken);
          await fetchInitialData(accessToken);

          logDebug("Calling SSEManager.connect()");
          SSEManager.connect("/api/nflnews/sse/nflnews");
          logDebug("Adding SSE message listener");
          SSEManager.addListener("/api/nflnews/sse/nflnews", handleSSEMessage);
          logDebug("SSE setup completed");
        }
      } catch (err) {
        console.error("Error in setupSSE:", err);
        if (flags?.consoleTrace) {
          console.trace(err);
        }
        if (isActive) {
          setError("Failed to connect to news service. Please try again later.");
        }
      }
    };

    setupSSE();

    return () => {
      isActive = false;
      logDebug("NFLNewsFooter component unmounting");
      logDebug("Removing SSE message listener");
      SSEManager.removeListener("/api/nflnews/sse/nflnews", handleSSEMessage);
      SSEManager.disconnect("/api/nflnews/sse/nflnews");
    };
  }, [getAccessTokenSilently, flags, handleSSEMessage, logDebug, fetchInitialData]);

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {nflNews.length > 0 ? (
        nflNews.map((nflnewsItem, index) => (
          <div key={index} style={{ marginLeft: "50px", flex: "0 1 auto" }}>
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <p style={{ margin: 0 }}>
                {nflnewsItem.image && (
                  <img
                    src={nflnewsItem.image}
                    alt={nflnewsItem.title}
                    style={{
                      width: "auto",
                      height: "40px",
                      marginRight: "10px",
                    }}
                  />
                )}
                {nflnewsItem.title}
              </p>
            </a>
          </div>
        ))
      ) : (
        <p>Loading news...</p>
      )}
    </div>
  );
});

export default NFLNewsFooter;