import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import SSEManager from "../components/SSEManager";
import {
  getSeasonTotalPoints,
  getAllPlayersFantasyPoints,
  getWeeklyPlayerStats,
} from "../services/fantasyPoints.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { Link } from "react-router-dom";

export const DashboardPage = withLDConsumer()(({ flags }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const ldClient = useLDClient();
  const currentSeason = process.env.REACT_APP_CURRENT_SEASON;

  const positions = useMemo(() => ["QB", "RB", "WR", "TE", "Team Defense"], []);
  const ldContextKey = useMemo(
    () => user.sub.replace("auth0|", ""),
    [user.sub]
  );

  const [dashboardData, setDashboardData] = useState({
    topSeasonTeams: [],
    topWeekTeams: [],
    topSeasonPlayers: [],
    topWeekPlayers: [],
    topPositionPlayers: {
      QB: { season: [], week: [] },
      RB: { season: [], week: [] },
      WR: { season: [], week: [] },
      TE: { season: [], week: [] },
      "Team Defense": { season: [], week: [] },
    },
    currentNFLWeek: 1,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    ldClient.identify({
      kind: "user",
      key: ldContextKey,
      name: user.name,
      email: user.email,
    });
  }, [ldClient, ldContextKey, user.name, user.email]);

  const updateDashboardData = useCallback((newData) => {
    setDashboardData((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const processTeamData = useCallback((regularSeasonData, currentWeek) => {
    const topSeason = regularSeasonData
      .sort((a, b) => b.totalPoints - a.totalPoints)
      .slice(0, 10);

    const topWeek = regularSeasonData
      .map((team) => ({
        ...team,
        weekPoints: team.weeklyPoints[`Week ${currentWeek}`] || 0,
      }))
      .sort((a, b) => b.weekPoints - a.weekPoints)
      .slice(0, 10);

    return { topSeason, topWeek };
  }, []);

  const fetchSeasonData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const [seasonResponse, playersResponse, weeklyStatsResponse] =
        await Promise.all([
          getSeasonTotalPoints(accessToken),
          getAllPlayersFantasyPoints(accessToken),
          getWeeklyPlayerStats(
            accessToken,
            dashboardData.currentNFLWeek.toString()
          ),
        ]);

      if (seasonResponse.error) {
        throw new Error(seasonResponse.error);
      }

      const updates = {
        currentNFLWeek: seasonResponse.currentNFLWeek,
      };

      if (Array.isArray(seasonResponse.regularSeasonData)) {
        const { topSeason, topWeek } = processTeamData(
          seasonResponse.regularSeasonData,
          seasonResponse.currentNFLWeek
        );
        updates.topSeasonTeams = topSeason;
        updates.topWeekTeams = topWeek;
      }

      if (playersResponse.success && Array.isArray(playersResponse.data)) {
        updates.topSeasonPlayers = playersResponse.data
          .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
          .slice(0, 10);

        const positionData = {};
        positions.forEach((position) => {
          const filteredPlayers =
            position === "Team Defense"
              ? playersResponse.data.filter(
                  (player) => player.position === "DEF"
                )
              : playersResponse.data.filter(
                  (player) => player.position === position
                );

          positionData[position] = {
            season: filteredPlayers
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10),
            week: [],
          };
        });
        updates.topPositionPlayers = positionData;
      }

      if (Array.isArray(weeklyStatsResponse)) {
        updates.topWeekPlayers = weeklyStatsResponse
          .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
          .slice(0, 10);

        if (updates.topPositionPlayers) {
          positions.forEach((position) => {
            const filteredPlayers =
              position === "Team Defense"
                ? weeklyStatsResponse.filter(
                    (player) => player.position === "DEF"
                  )
                : weeklyStatsResponse.filter(
                    (player) => player.position === position
                  );

            updates.topPositionPlayers[position].week = filteredPlayers
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10);
          });
        }
      }

      updateDashboardData(updates);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false); // Only set loading to false after initial data fetch
    }
  }, [
    getAccessTokenSilently,
    positions,
    processTeamData,
    updateDashboardData,
    dashboardData.currentNFLWeek,
  ]);

  const handleSSEMessage = useCallback(
    (data) => {
      console.log("SSE Message received:", data);
      try {
        let updates = {};

        if (data.type === "standings") {
          const { topSeason, topWeek } = processTeamData(
            data.data.regularSeasonData,
            data.data.currentNFLWeek
          );
          updates = {
            currentNFLWeek: data.data.currentNFLWeek,
            topSeasonTeams: topSeason,
            topWeekTeams: topWeek,
          };
        }

        if (data.type === "playerStats") {
          if (data.data.seasonStats) {
            updates.topSeasonPlayers = data.data.seasonStats
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10);

            const positionData = {};
            positions.forEach((position) => {
              const filteredPlayers =
                position === "Team Defense"
                  ? data.data.seasonStats.filter(
                      (player) =>
                        player.position === "DEF" ||
                        player.position === "Team Defense"
                    )
                  : data.data.seasonStats.filter(
                      (player) => player.position === position
                    );

              positionData[position] = {
                season: filteredPlayers
                  .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
                  .slice(0, 10),
                week: [],
              };
            });
            updates.topPositionPlayers = positionData;
          }

          if (data.data.weeklyStats) {
            updates.topWeekPlayers = data.data.weeklyStats
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10);

            if (!updates.topPositionPlayers) {
              updates.topPositionPlayers = {};
            }

            positions.forEach((position) => {
              let filteredPlayers =
                position === "Team Defense"
                  ? data.data.weeklyStats.filter(
                      (player) =>
                        player.position === "DEF" ||
                        player.position === "Team Defense"
                    )
                  : data.data.weeklyStats.filter(
                      (player) => player.position === position
                    );

              if (!updates.topPositionPlayers[position]) {
                updates.topPositionPlayers[position] = {
                  season: [],
                  week: [],
                };
              }

              updates.topPositionPlayers[position].week = filteredPlayers
                .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
                .slice(0, 10);
            });
          }
        }

        if (Object.keys(updates).length > 0) {
          updateDashboardData(updates);
        }
      } catch (error) {
        console.error("Error processing SSE message:", error);
        setError(`Error processing live updates: ${error.message}`);
      }
    },
    [positions, processTeamData, updateDashboardData]
  );

  useEffect(() => {
    const SSE_ENDPOINT = "/api/fantasypoints/sse";

    const setupSSEConnection = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        SSEManager.setAccessToken(accessToken);
        SSEManager.addListener(SSE_ENDPOINT, handleSSEMessage);
        console.log("SSE connection established");
      } catch (err) {
        console.error("Error setting up SSE:", err);
        setError(`Error setting up live updates: ${err.message}`);
      }
    };

    // Initial setup
    setupSSEConnection();

    // Cleanup
    return () => {
      SSEManager.removeListener(SSE_ENDPOINT, handleSSEMessage);
    };
  }, [getAccessTokenSilently, handleSSEMessage]);

  // Separate effect for initial data fetch
  useEffect(() => {
    fetchSeasonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array since we only want this to run once

  const calculatePreviousRankings = useCallback(
    (tableData) => {
      if (!tableData || tableData.length === 0) return new Map();

      // For week rankings, we'll compare against previous week
      // For season rankings, we'll calculate rankings without current week points
      const prevWeekData = tableData.map((manager) => ({
        ...manager,
        totalPoints: Object.entries(manager.weeklyPoints).reduce(
          (sum, [week, points]) => {
            const weekNum = parseInt(week.replace("Week ", ""));
            if (weekNum < dashboardData.currentNFLWeek) {
              return sum + points;
            }
            return sum;
          },
          0
        ),
      }));

      prevWeekData.sort((a, b) => b.totalPoints - a.totalPoints);

      const rankMap = new Map();
      prevWeekData.forEach((manager, index) => {
        rankMap.set(manager.managerEmail, index + 1);
      });

      return rankMap;
    },
    [dashboardData.currentNFLWeek]
  );

  const renderTeamTable = useCallback(
    (title, data, pointsKey) => {
      const previousRankings = calculatePreviousRankings(data);

      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={title}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          <div className="table-responsive">
            <Table
              striped
              bordered
              hover
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  {pointsKey === "totalPoints" && <th className="dashboard-rank-change-column"></th>}
                  <th className="dashboard-team-column">Team</th>
                  <th className="dashboard-points-column">Points</th>
                </tr>
              </thead>
              <tbody>
                {data.map((team, index) => (
                  <tr key={team.managerEmail}>
                    <td className="dashboard-rank-column">{index + 1}</td>
                    {pointsKey === "totalPoints" && (
                      <td className="dashboard-rank-change-column" style={{ padding: 0, whiteSpace: 'nowrap' }}>
                        {dashboardData.currentNFLWeek > 1 && 
                          (() => {
                            const previousRank = previousRankings.get(team.managerEmail);
                            if (previousRank === index + 1) return null;
                            if (previousRank > index + 1) {
                              return <CaretUpFill className="text-success" size={12} />;
                            }
                            return <CaretDownFill className="text-danger" size={12} />;
                          })()
                        }
                      </td>
                    )}
                    <td className="dashboard-team-column">
                      <Link
                        to={`/fantasypoints/${encodeURIComponent(
                          team.teamName || team.managerEmail
                        )}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {team.teamName || team.managerEmail}
                      </Link>
                    </td>
                    <td className="dashboard-points-column">
                      {pointsKey === "weekPoints"
                        ? team.weekPoints
                        : team[pointsKey]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    },
    [fontSize, theme, dashboardData.currentNFLWeek, calculatePreviousRankings]
  );

  const renderPlayerTable = useCallback(
    (title, data, isWeekly = false, isPositionSpecific = false) => {
      const isTeamDefense =
        title.includes("Team Defense") || title.includes("DEF");

      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={title}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          <div className="table-responsive">
            <Table
              striped
              bordered
              hover
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  <th className="dashboard-team-column">
                    {isTeamDefense ? "Team Defense" : "Player Name"}
                  </th>
                  {!isPositionSpecific && !isTeamDefense && <th>Position</th>}
                  {!isTeamDefense && <th>Team</th>}
                  <th className="dashboard-points-column">Points</th>
                </tr>
              </thead>
              <tbody>
                {data.map((player, index) => (
                  <tr key={player.playerId || index}>
                    <td className="dashboard-rank-column">{index + 1}</td>
                    <td className="dashboard-team-column">
                      {isTeamDefense
                        ? player.longName || player.playerName || player.team
                        : player.position === "DEF"
                        ? player.longName ||
                          player.playerName ||
                          `${player.team} Defense`
                        : player.playerName}
                    </td>
                    {!isPositionSpecific && !isTeamDefense && (
                      <td>
                        {player.position === "Team Defense"
                          ? "DEF"
                          : player.position}
                      </td>
                    )}
                    {!isTeamDefense && <td>{player.team || player.teamAbv}</td>}
                    <td className="dashboard-points-column">
                      {player.totalFantasyPoints}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    },
    [fontSize, theme]
  );

  const renderPositionTables = useCallback(() => {
    return (
      <Tabs defaultActiveKey="QB" id="position-tabs" className="mb-3">
        {positions.map((position) => (
          <Tab eventKey={position} title={position} key={position}>
            <Row>
              <Col md={6}>
                {renderPlayerTable(
                  `Top 10 ${
                    position === "Team Defense" ? "DEF" : position
                  } - ${currentSeason} Season`,
                  dashboardData.topPositionPlayers[position]?.season || [],
                  false,
                  true
                )}
              </Col>
              <Col md={6}>
                {renderPlayerTable(
                  `Top 10 ${
                    position === "Team Defense" ? "DEF" : position
                  } - Week ${dashboardData.currentNFLWeek}`,
                  dashboardData.topPositionPlayers[position]?.week || [],
                  true,
                  true
                )}
              </Col>
            </Row>
          </Tab>
        ))}
      </Tabs>
    );
  }, [
    positions,
    currentSeason,
    dashboardData.currentNFLWeek,
    dashboardData.topPositionPlayers,
    renderPlayerTable,
  ]);

  if (loading) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  if (!flags.dashboardComponent) {
    return (
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard
        </h4>
        <br />
        <h1>The TUFF Dashboard is currently disabled.</h1>
      </Container>
    );
  }

  return (
    <div className="dashboard-wrapper">
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard
        </h4>
        <br />

        {error && <Alert variant="danger">{error}</Alert>}

        <div className="dashboard-content">
          <Row className="justify-content-center">
            <Col md={6}>
              {renderTeamTable(
                `Top 10 Fantasy Points - ${currentSeason} Season`,
                dashboardData.topSeasonTeams,
                "totalPoints"
              )}
            </Col>
            <Col md={6}>
              {renderTeamTable(
                `Top 10 Fantasy Points - Week ${dashboardData.currentNFLWeek}`,
                dashboardData.topWeekTeams,
                "weekPoints"
              )}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={6}>
              {renderPlayerTable(
                `Top 10 Players - ${currentSeason} Season`,
                dashboardData.topSeasonPlayers
              )}
            </Col>
            <Col md={6}>
              {renderPlayerTable(
                `Top 10 Players - Week ${dashboardData.currentNFLWeek}`,
                dashboardData.topWeekPlayers,
                true
              )}
            </Col>
          </Row>

          <section className="position-details">
            {renderPositionTables()}
          </section>
        </div>
      </Container>
    </div>
  );
});

export default DashboardPage;
