import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { VersionChecker } from "./components/VersionChecker";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { HomePage } from "./pages/HomePage";
import { DashboardPage } from "./pages/DashboardPage";
import { FantasyPointsPage } from "./pages/FantasyPointsPage";
import { PlayerFantasyPointsPage } from "./pages/PlayerFantasyPointsPage";
import { WeeklyPlayerStatsPage } from "./pages/WeeklyPlayerStatsPage";
import { TUFFStandingsPage } from "./pages/TUFFStandingsPage";
import { RosterPage } from "./pages/RosterPage";
import { RosterManagementPage } from "./pages/RosterManagementPage";
import { NFLNewsPage } from "./pages/NFLNewsPage";
import { ProfilePage } from "./pages/profile-page";
import { NotFoundPage } from "./pages/NotFoundPage";
import { MemoizedPageLayout as PageLayout } from "./components/PageLayout";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <>
      <VersionChecker />
      <PageLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={DashboardPage} />}
          />
          <Route
            path="/fantasypoints"
            element={<AuthenticationGuard component={FantasyPointsPage} />}
          />
          <Route
            path="/fantasypoints/:team"
            element={<AuthenticationGuard component={FantasyPointsPage} />}
          />
          <Route
            path="/playerfantasypoints"
            element={<AuthenticationGuard component={PlayerFantasyPointsPage} />}
          />
          <Route
            path="/weeklyplayerstats"
            element={<AuthenticationGuard component={WeeklyPlayerStatsPage} />}
          />
          <Route
            path="/tuffstandings"
            element={<AuthenticationGuard component={TUFFStandingsPage} />}
          />
          <Route
            path="/roster"
            element={<AuthenticationGuard component={RosterPage} />}
          />
          <Route
            path="/commissioner/rostermanagement"
            element={<AuthenticationGuard component={RosterManagementPage} />}
          />
          <Route
            path="/nflnews"
            element={<AuthenticationGuard component={NFLNewsPage} />}
          />
          <Route
            path="/profile"
            element={<AuthenticationGuard component={ProfilePage} />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PageLayout>
    </>
  );
};