import React from 'react';
import { Container, Card } from "react-bootstrap";
import LoginButton from "../auth/login";
import cardImage from "../assets/img/footballcash.png";

const CURRENT_SEASON = process.env.REACT_APP_CURRENT_SEASON;

export const HomePageCard = () => {
  return (
    <Container className="home-page-container d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div className="card-container">
        <Card className="home-page-card">
          <Card.Img variant="top" src={cardImage} />
          <Card.Body>
            <Card.Text>TUFF</Card.Text>
            <Card.Text>Fantasy Football</Card.Text>
            <Card.Text>{CURRENT_SEASON}</Card.Text>
            <LoginButton />
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};