import { callExternalApi } from './external-api.service';
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllowedCommissionerEmails = async (token) => {
  const config = {
    method: 'get',
    url: `${apiServerUrl}/api/commissioner/check-commissioner`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await callExternalApi({ config });
};

export const getAllTrades = async (token) => {
  const config = {
    method: 'get',
    url: `${apiServerUrl}/api/commissioner/trades`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await callExternalApi({ config });
};

export const createTrade = async (token, tradeData) => {
  const config = {
    method: 'post',
    url: `${apiServerUrl}/api/commissioner/trades`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(tradeData),
  };
  return await callExternalApi({ config });
};

export const updateTrade = async (token, tradeId, tradeData) => {
  const config = {
    method: 'put',
    url: `${apiServerUrl}/api/commissioner/trades/${tradeId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(tradeData),
  };
  return await callExternalApi({ config });
};

export const deleteTrade = async (token, tradeId) => {
  const config = {
    method: 'delete',
    url: `${apiServerUrl}/api/commissioner/trades/${tradeId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await callExternalApi({ config });
};

export const updatePlayerInRoster = async (token, rosterData) => {
  const config = {
    method: 'post',
    url: `${apiServerUrl}/api/commissioner/update-roster`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(rosterData),
  };
  return await callExternalApi({ config });
};

export const getEligiblePlayers = async (token, week, position, managerEmail) => {
  const config = {
    method: 'get',
    url: `${apiServerUrl}/api/commissioner/eligible-players/${week}/${position}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { email: managerEmail },
  };
  return await callExternalApi({ config });
};