import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getFantasyPoints = async (accessToken, managerEmail, week) => {
  const encodedEmail = encodeURIComponent(managerEmail);
  const encodedWeek = encodeURIComponent(week);
  const url = `${apiServerUrl}/api/fantasypoints/${encodedEmail}/${encodedWeek}`;
  console.log('getFantasyPoints called with:', { managerEmail, week });
  console.log('Constructed URL:', url);

  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getFantasyPoints:', error);
    return { fantasyPoints: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getSeasonTotalPoints = async (accessToken) => {
  const url = `${apiServerUrl}/api/fantasypoints/season-total`;
  console.log('getSeasonTotalPoints called');
  console.log('Constructed URL:', url);

  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getSeasonTotalPoints:', error);
    return { seasonTotalPoints: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getTeamNames = async (accessToken) => {
  try {
    const response = await callExternalApi({
      config: {
        url: `${apiServerUrl}/api/fantasypoints/teams`,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });

    console.log("Team Names API Response:", response.data); // Add this line

    if (response.error) {
      throw new Error(response.error);
    }

    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error in getTeamNames:", error);
    return { data: null, error: error.message };
  }
};

export const getAllPlayersFantasyPoints = async (accessToken) => {
  const url = `${apiServerUrl}/api/fantasypoints/all-players`;
  console.log('getAllPlayersFantasyPoints called');
  console.log('Constructed URL:', url);
  
  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getAllPlayersFantasyPoints:', error);
    return { data: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getWeeklyPlayerStats = async (accessToken, week) => {
  const encodedWeek = encodeURIComponent(week);
  const url = `${apiServerUrl}/api/fantasypoints/weekly-stats/${encodedWeek}`;
  console.log('getWeeklyPlayerStats called with:', { week });
  console.log('Constructed URL:', url);
  
  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    console.log('Raw API response:', response);

    // Check if we have a valid response
    if (!response.data) {
      throw new Error('No data in API response');
    }

    // Log the actual data structure
    console.log('Response data structure:', response.data);

    // Return the data directly - the backend is already formatting it correctly
    return response.data;

  } catch (error) {
    console.error('Error in getWeeklyPlayerStats:', error);
    throw error;
  }
};