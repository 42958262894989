import React from "react";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import { useTheme } from "../contexts/ThemeContext";

const StyledTableBase = styled(Table)`
  /* Header styles */
  thead tr th {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white) !important;
    border-color: var(--bs-primary) !important;
    position: sticky;
    top: 0;
    z-index: 1;
    white-space: nowrap;
    vertical-align: middle;
  }

  /* Dark mode header adjustment */
  [data-bs-theme="dark"] & thead tr th {
    background-color: var(--bs-primary-dark, var(--bs-primary)) !important;
    border-color: var(--bs-primary-dark, var(--bs-primary)) !important;
  }

  /* Existing styles */
  tbody tr:nth-of-type(odd) {
    background-color: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
  }

  tbody tr:nth-of-type(even) {
    background-color: transparent;
  }

  tbody tr:hover {
    background-color: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.075)'};
  }

  /* Rank-based cell styles */
  tbody tr td.custom-bg.primary {
    background-color: #0d6efd !important;
    color: white !important;
  }

  tbody tr td.custom-bg.warning {
    background-color: #ffc107 !important;
    color: ${props => props.theme === 'dark' ? 'black' : 'black'} !important;
  }

  tbody tr td.custom-bg.danger {
    background-color: #dc3545 !important;
    color: white !important;
  }

  tbody tr td.custom-bg.success {
    background-color: #198754 !important;
    color: white !important;
  }

  tbody tr td.custom-bg.info {
    background-color: #0dcaf0 !important;
    color: ${props => props.theme === 'dark' ? 'black' : 'black'} !important;
  }

  /* Ensure rank-based colors are preserved on hover */
  tbody tr:hover td.custom-bg.primary,
  tbody tr:hover td.custom-bg.warning,
  tbody tr:hover td.custom-bg.danger,
  tbody tr:hover td.custom-bg.success,
  tbody tr:hover td.custom-bg.info {
    opacity: 1 !important;
  }

  /* Responsive styles */
  @media (max-width: 767px) {
    .desktop-only {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .mobile-only {
      display: none;
    }
  }
`;

export const StyledTable = (props) => {
  const { theme } = useTheme();
  return <StyledTableBase {...props} theme={theme} />;
};