import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./contexts/auth0-provider-with-navigate";
import { LDProvider } from "./contexts/LaunchDarklyContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { FontSizeProvider } from "./contexts/FontSizeContext";
import { App } from "./app";
import "./styles/styles.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

const container = document.getElementById("root");
const root = createRoot(container);

const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

const AppContent = (
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <LDProvider>
        <ThemeProvider>
          <FontSizeProvider>
            <App />
          </FontSizeProvider>
        </ThemeProvider>
      </LDProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

root.render(
  isProduction ? AppContent : <React.StrictMode>{AppContent}</React.StrictMode>
);
