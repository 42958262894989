import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

export const VersionChecker = () => {
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const currentVersion = window.APP_VERSION;

  useEffect(() => {
    const checkVersion = () => {
      const storedVersion = localStorage.getItem('appVersion');
      if (storedVersion && storedVersion !== currentVersion) {
        setNeedsUpdate(true);
      } else {
        localStorage.setItem('appVersion', currentVersion);
      }
    };

    // Check immediately on mount
    checkVersion();

    // Then check every 60 seconds
    const intervalId = setInterval(checkVersion, 60000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [currentVersion]);

  const handleUpdate = () => {
    localStorage.setItem('appVersion', currentVersion);
    window.location.reload(true);
  };

  if (needsUpdate) {
    return (
      <Alert 
        variant="warning" 
        className="m-0" 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          borderRadius: 0,
          textAlign: 'center',
        }}
      >
        A new version is available. Please update now to ensure you have the latest features and fixes.
        <Button 
          variant="primary" 
          size="sm" 
          className="ml-2"
          onClick={handleUpdate}
        >
          Update Now
        </Button>
      </Alert>
    );
  }

  return null;
};