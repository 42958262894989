import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getProtectedResource = async (accessToken, position, week) => {
  console.log(`Fetching ${position} players for week ${week}`);
  const config = {
    url: `${apiServerUrl}/api/players/${position.toLowerCase()}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      week: week,
    },
  };

  console.log("API request config:", JSON.stringify(config, null, 2));

  try {
    const { data, error } = await callExternalApi({ config });

    if (error) {
      console.error("Error fetching protected resource:", error);
    } else {
      console.log(`Received ${data?.length || 0} players for ${position}`);
      if (data && data.length > 0) {
        console.log("Sample player data:", data[0]);
      }
    }

    return { data, error };
  } catch (error) {
    console.error("Unexpected error in getProtectedResource:", error);
    return { data: null, error: error.message };
  }
};

export const searchPlayers = async (accessToken, searchTerm) => {
  console.log(`Searching players with term: ${searchTerm}`);
  const config = {
    url: `${apiServerUrl}/api/players/search`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      term: searchTerm,
    },
  };
  
  try {
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error("Error searching players:", error);
    } else {
      console.log(`Received ${data?.length || 0} players for search term: ${searchTerm}`);
      console.log("Sample player data:", data?.length > 0 ? data[0] : "No players found");
    }
    return { data, error };
  } catch (error) {
    console.error("Unexpected error in searchPlayers:", error);
    return { data: null, error: error.message };
  }
};