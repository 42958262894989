import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Container,
  Alert,
  Spinner,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { getSeasonTotalPoints } from "../services/fantasyPoints.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { StyledTable } from "../components/StyledTable";
import SSEManager from "../components/SSEManager";

export const TUFFStandingsPage = () => {
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently } = useAuth0();

  const [isProcessing, setIsProcessing] = useState(true);
  const [seasonData, setSeasonData] = useState({
    regularSeason: [],
    playoffs: [],
  });
  const [currentNFLWeek, setCurrentNFLWeek] = useState(1);
  const [weeklyRankings, setWeeklyRankings] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [renderProgress, setRenderProgress] = useState(0);
  const [weekViewMode, setWeekViewMode] = useState("recent");

  const progressRef = useRef(0);
  const animationFrameRef = useRef(null);

  const headerFontSize = useMemo(
    () => `${parseFloat(fontSize) * 1.5}px`,
    [fontSize]
  );
  const regularSeasonWeeks = 14;
  const playoffWeeks = 3;

  const memoizedWeeklyRankings = useMemo(() => {
    return JSON.stringify(weeklyRankings);
  }, [weeklyRankings]);

  const getColorForRank = useCallback((rank) => {
    if (rank === 1) return "primary";
    if (rank === 2) return "warning";
    if (rank === 3) return "danger";
    if (rank === 4) return "info";
    if (rank >= 5 && rank <= 10) return "success";
    return "";
  }, []);

  const updateProgress = useCallback(() => {
    if (progressRef.current > renderProgress) {
      setRenderProgress(progressRef.current);
    }
    animationFrameRef.current = requestAnimationFrame(updateProgress);
  }, [renderProgress]);

  const calculateBonusPayouts = useCallback(
    (data) => {
      const regularSeasonData = data.slice(0, regularSeasonWeeks);
      const sortedData = [...regularSeasonData].sort(
        (a, b) => b.totalPoints - a.totalPoints
      );

      const totalBonusPool = 175;
      const bonusPayouts = new Array(sortedData.length).fill(0);

      const firstPlaceScore = sortedData[0].totalPoints;
      const secondPlaceScore = sortedData[1].totalPoints;
      const thirdPlaceScore = sortedData[2].totalPoints;

      const teamsWithFirstPlaceScore = sortedData.filter(
        (team) => team.totalPoints === firstPlaceScore
      );
      const teamsWithSecondPlaceScore = sortedData.filter(
        (team) => team.totalPoints === secondPlaceScore
      );
      const teamsWithThirdPlaceScore = sortedData.filter(
        (team) => team.totalPoints === thirdPlaceScore
      );

      if (teamsWithFirstPlaceScore.length >= 3) {
        const bonusPerTeam = totalBonusPool / teamsWithFirstPlaceScore.length;
        teamsWithFirstPlaceScore.forEach((team) => {
          const index = sortedData.findIndex(
            (t) => t.managerEmail === team.managerEmail
          );
          bonusPayouts[index] = bonusPerTeam;
        });
      } else if (teamsWithFirstPlaceScore.length === 2) {
        const bonusForFirst = (100 + 50) / 2;
        teamsWithFirstPlaceScore.forEach((team) => {
          const index = sortedData.findIndex(
            (t) => t.managerEmail === team.managerEmail
          );
          bonusPayouts[index] = bonusForFirst;
        });
        if (teamsWithThirdPlaceScore.length > 1) {
          const bonusForThird = 25 / teamsWithThirdPlaceScore.length;
          teamsWithThirdPlaceScore.forEach((team) => {
            const index = sortedData.findIndex(
              (t) => t.managerEmail === team.managerEmail
            );
            bonusPayouts[index] = bonusForThird;
          });
        } else {
          bonusPayouts[2] = 25;
        }
      } else if (teamsWithSecondPlaceScore.length > 1) {
        bonusPayouts[0] = 100;
        const bonusForSecond = 75 / teamsWithSecondPlaceScore.length;
        teamsWithSecondPlaceScore.forEach((team) => {
          const index = sortedData.findIndex(
            (t) => t.managerEmail === team.managerEmail
          );
          bonusPayouts[index] = bonusForSecond;
        });
      } else {
        bonusPayouts[0] = 100;
        bonusPayouts[1] = 50;
        bonusPayouts[2] = 25;
      }

      return bonusPayouts;
    },
    [regularSeasonWeeks]
  );

  const formatDollarAmount = useCallback((amount) => {
    if (Math.floor(amount) === amount) {
      return `$${amount.toFixed(0)}`;
    } else {
      return `$${amount.toFixed(2)}`;
    }
  }, []);

  const getManagerRank = useCallback((managerEmail, data) => {
    const sortedData = [...data].sort((a, b) => b.totalPoints - a.totalPoints);
    return (
      sortedData.findIndex((manager) => manager.managerEmail === managerEmail) +
      1
    );
  }, []);

  const fetchSeasonData = useCallback(async () => {
    setLoading(true);
    setIsProcessing(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const totalPointsResponse = await getSeasonTotalPoints(accessToken);

      if (totalPointsResponse.error) throw new Error(totalPointsResponse.error);

      const newSeasonData = {
        regularSeason: totalPointsResponse.regularSeasonData,
        playoffs: totalPointsResponse.playoffData,
      };

      if (totalPointsResponse.regularSeasonData.length > 0) {
        const bonusPayouts = calculateBonusPayouts(
          totalPointsResponse.regularSeasonData
        );
        newSeasonData.bonusPayouts = bonusPayouts;
      }

      setCurrentNFLWeek(totalPointsResponse.currentNFLWeek);
      setSeasonData(newSeasonData);
      setWeeklyRankings(totalPointsResponse.weeklyRankings);
    } catch (err) {
      console.error("Error fetching season data:", err);
      setError(`Error fetching season data: ${err.message}`);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  }, [getAccessTokenSilently, calculateBonusPayouts]);

  const handleSSEMessage = useCallback(
    (data) => {
      console.log("Received SSE message:", data);
      if (data.type === "standings") {
        setIsProcessing(true);
        try {
          const newSeasonData = {
            regularSeason: data.data.regularSeasonData,
            playoffs: data.data.playoffData,
          };

          if (data.data.regularSeasonData.length > 0) {
            const bonusPayouts = calculateBonusPayouts(
              data.data.regularSeasonData
            );
            newSeasonData.bonusPayouts = bonusPayouts;
          }

          setCurrentNFLWeek(data.data.currentNFLWeek);
          setSeasonData(newSeasonData);
          setWeeklyRankings(data.data.weeklyRankings);

          console.log("Updated standings data from SSE message");
        } catch (error) {
          console.error("Error processing SSE message:", error);
          setError(`Error processing live updates: ${error.message}`);
        } finally {
          setIsProcessing(false);
        }
      }
    },
    [calculateBonusPayouts]
  );

  const renderTable = useCallback(
    (data, title, isPlayoff = false) => {
      console.log("Rendering table:", title);

      const weeklyRankings = JSON.parse(memoizedWeeklyRankings);

      // Calculate previous rankings here, inside renderTable
      const calculatePreviousRankings = (tableData) => {
        const prevWeekData = tableData.map((manager) => ({
          ...manager,
          totalPoints: Object.entries(manager.weeklyPoints).reduce(
            (sum, [week, points]) => {
              const weekNum = parseInt(week.replace("Week ", ""));
              if (weekNum < currentNFLWeek) {
                return sum + points;
              }
              return sum;
            },
            0
          ),
        }));

        prevWeekData.sort((a, b) => b.totalPoints - a.totalPoints);

        const rankMap = new Map();
        prevWeekData.forEach((manager, index) => {
          rankMap.set(manager.managerEmail, index + 1);
        });

        return rankMap;
      };

      const previousRankings = calculatePreviousRankings(data);

      let displayWeeks;
      let calculationWeeks;
      let mobileWeek;
      const effectiveWeek = isPlayoff
        ? currentNFLWeek
        : Math.min(currentNFLWeek, regularSeasonWeeks);

      if (isPlayoff) {
        const playoffWeeksToShow = Math.max(
          0,
          Math.min(currentNFLWeek - regularSeasonWeeks, playoffWeeks)
        );
        displayWeeks = Array.from(
          { length: playoffWeeksToShow },
          (_, i) => `Week ${i + regularSeasonWeeks + 1}`
        );
        calculationWeeks = displayWeeks;
      } else {
        // Get all possible weeks up to the effective week for calculations
        calculationWeeks = Array.from(
          { length: effectiveWeek },
          (_, i) => `Week ${i + 1}`
        );

        // Filter weeks for display based on view mode
        displayWeeks = weekViewMode === "all" ? calculationWeeks : calculationWeeks.slice(-4);
      }

      if (displayWeeks.length === 0) return null;

      // Always show the latest week in mobile view
      mobileWeek = displayWeeks[displayWeeks.length - 1];

      const highestTotalPoints = Math.max(
        ...data.map((manager) => manager.totalPoints)
      );
      const sixthPlacePoints =
        data.find((m) => getManagerRank(m.managerEmail, data) === 6)
          ?.totalPoints || 0;

      const showBonusAndPlayoffColumns = currentNFLWeek > regularSeasonWeeks;

      const achievementCounts = data.map((manager) => {
        const counts = {
          top10: calculationWeeks.filter(
            (week) =>
              weeklyRankings[week]?.[manager.managerEmail] >= 1 &&
              weeklyRankings[week]?.[manager.managerEmail] <= 10 &&
              (manager.weeklyPoints[week] || 0) > 0
          ).length,
          gotw: calculationWeeks.filter(
            (week) =>
              weeklyRankings[week]?.[manager.managerEmail] === 1 &&
              (manager.weeklyPoints[week] || 0) > 0
          ).length,
          secondPlace: calculationWeeks.filter(
            (week) =>
              weeklyRankings[week]?.[manager.managerEmail] === 2 &&
              (manager.weeklyPoints[week] || 0) > 0
          ).length,
          thirdPlace: calculationWeeks.filter(
            (week) =>
              weeklyRankings[week]?.[manager.managerEmail] === 3 &&
              (manager.weeklyPoints[week] || 0) > 0
          ).length,
          fourthPlace: calculationWeeks.filter(
            (week) =>
              weeklyRankings[week]?.[manager.managerEmail] === 4 &&
              (manager.weeklyPoints[week] || 0) > 0
          ).length,
        };

        const regularSeasonEarnings =
          counts.top10 * 5 +
          counts.gotw * 35 +
          counts.secondPlace * 25 +
          counts.thirdPlace * 15 +
          counts.fourthPlace * 5;

        return { ...counts, regularSeasonEarnings };
      });

      const bonusPayouts = calculateBonusPayouts(data);
      const playoffEarnings = [320, 175, 105, 70, 35, 0];

      return (
        <div className="tuff-standings-wrapper">
          <h4
            className="dashboard-table-title"
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          {!isPlayoff && (
            <div className="mb-3 text-center d-none d-md-flex justify-content-center position-relative">
              <ButtonGroup size="sm">
                <ToggleButton
                  id="recent-weeks"
                  type="radio"
                  variant={theme === 'dark' ? 'outline-light' : 'outline-dark'}
                  name="week-view"
                  value="recent"
                  checked={weekViewMode === 'recent'}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  Recent Weeks
                </ToggleButton>
                <ToggleButton
                  id="all-weeks"
                  type="radio"
                  variant={theme === 'dark' ? 'outline-light' : 'outline-dark'}
                  name="week-view"
                  value="all"
                  checked={weekViewMode === 'all'}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  All Weeks
                </ToggleButton>
              </ButtonGroup>
              {weekViewMode === 'recent' && (
                <div className="position-absolute start-50 translate-middle-x" style={{ top: '100%', fontSize: fontSize }}>
                  <small className="text-muted">
                    Showing last 3 weeks + current week
                  </small>
                </div>
              )}
            </div>
          )}
          <div className="table-responsive">
            <StyledTable
              bordered
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  <th className="dashboard-rank-change-column"></th>
                  <th className="dashboard-team-column">Team</th>
                  <th className="mobile-only dashboard-week-column">
                    {mobileWeek}
                  </th>
                  {displayWeeks.map((week) => (
                    <th
                      key={week}
                      className="desktop-only dashboard-week-column"
                    >
                      {week}
                    </th>
                  ))}
                  <th className="dashboard-points-column">
                    {isPlayoff ? "Playoff Total" : "Season Total"}
                  </th>
                  {!isPlayoff && (
                    <>
                      <th className="dashboard-points-column">Points Back</th>
                      <th className="dashboard-points-column">
                        Points from 6th
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Top 10
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        GOTW
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        2nd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        3rd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        4th Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Regular Season $
                      </th>
                      {showBonusAndPlayoffColumns && (
                        <>
                          <th className="desktop-only dashboard-points-column">
                            Reg Season Bonus $
                          </th>
                          <th className="desktop-only dashboard-points-column">
                            Playoffs $
                          </th>
                        </>
                      )}
                      <th className="desktop-only dashboard-points-column">
                        Total $
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((manager) => {
                  const managerRank = getManagerRank(
                    manager.managerEmail,
                    data
                  );
                  const bonusPayout = bonusPayouts[managerRank - 1];

                  return (
                    <tr key={manager.managerEmail}>
                      <td className="dashboard-rank-column">{managerRank}</td>
                      <td className="dashboard-rank-change-column">
                        {!isPlayoff && currentNFLWeek > 1 && (
                          <>
                            {(() => {
                              const previousRank = previousRankings.get(
                                manager.managerEmail
                              );
                              if (previousRank === managerRank) return null;
                              if (previousRank > managerRank) {
                                return (
                                  <CaretUpFill
                                    className="text-success"
                                    size={12}
                                  />
                                );
                              } else {
                                return (
                                  <CaretDownFill
                                    className="text-danger"
                                    size={12}
                                  />
                                );
                              }
                            })()}
                          </>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/fantasypoints/${encodeURIComponent(
                            manager.teamName || manager.managerEmail
                          )}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {manager.teamName || manager.managerEmail}
                        </Link>
                      </td>
                      <td className="mobile-only">
                        {(() => {
                          const weeklyPoints =
                            manager.weeklyPoints[mobileWeek] || 0;
                          const rank =
                            weeklyRankings[mobileWeek]?.[manager.managerEmail];
                          const colorClass =
                            weeklyPoints > 0 ? getColorForRank(rank) : "";
                          return (
                            <div
                              className={`mobile-week-points ${
                                colorClass ? `custom-bg ${colorClass}` : ""
                              }`}
                            >
                              {weeklyPoints}
                            </div>
                          );
                        })()}
                      </td>
                      {displayWeeks.map((week) => {
                        const weeklyPoints = manager.weeklyPoints[week] || 0;
                        const rank =
                          weeklyRankings[week]?.[manager.managerEmail];
                        const colorClass =
                          weeklyPoints > 0 ? getColorForRank(rank) : "";
                        return (
                          <td
                            key={week}
                            className={`desktop-only dashboard-week-column ${
                              colorClass ? `custom-bg ${colorClass}` : ""
                            }`}
                          >
                            {weeklyPoints}
                          </td>
                        );
                      })}
                      <td>{manager.totalPoints}</td>
                      {!isPlayoff && (
                        <>
                          <td>{highestTotalPoints - manager.totalPoints}</td>
                          <td>
                            {managerRank <= 6
                              ? 0
                              : Math.max(
                                  0,
                                  sixthPlacePoints - manager.totalPoints
                                )}
                          </td>
                          <td className="desktop-only">
                            {achievementCounts[managerRank - 1].top10}
                          </td>
                          <td className="desktop-only">
                            {achievementCounts[managerRank - 1].gotw}
                          </td>
                          <td className="desktop-only">
                            {achievementCounts[managerRank - 1].secondPlace}
                          </td>
                          <td className="desktop-only">
                            {achievementCounts[managerRank - 1].thirdPlace}
                          </td>
                          <td className="desktop-only">
                            {achievementCounts[managerRank - 1].fourthPlace}
                          </td>
                          <td className="desktop-only">
                            {formatDollarAmount(
                              achievementCounts[managerRank - 1]
                                .regularSeasonEarnings
                            )}
                          </td>
                          {showBonusAndPlayoffColumns && (
                            <>
                              <td className="desktop-only">
                                {formatDollarAmount(bonusPayout)}
                              </td>
                              <td className="desktop-only">
                                {formatDollarAmount(
                                  playoffEarnings[managerRank - 1] || 0
                                )}
                              </td>
                            </>
                          )}
                          <td className="desktop-only">
                            {formatDollarAmount(
                              achievementCounts[managerRank - 1]
                                .regularSeasonEarnings +
                                (showBonusAndPlayoffColumns
                                  ? bonusPayout +
                                    (playoffEarnings[managerRank - 1] || 0)
                                  : 0)
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </div>
        </div>
      );
    },
    [
      currentNFLWeek,
      theme,
      memoizedWeeklyRankings,
      regularSeasonWeeks,
      playoffWeeks,
      getColorForRank,
      calculateBonusPayouts,
      formatDollarAmount,
      getManagerRank,
      weekViewMode,
      fontSize,
    ]
  );

  const renderLegend = useCallback(() => {
    const legendItems = [
      { rank: "GOTW", bootstrapClass: "primary" },
      { rank: "2nd Place", bootstrapClass: "warning" },
      { rank: "3rd Place", bootstrapClass: "danger" },
      { rank: "4th Place", bootstrapClass: "info" },
      { rank: "Top 10", bootstrapClass: "success" },
    ];

    return (
      <div className="mt-3 text-center">
        <h6>Legend:</h6>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {legendItems.map((item) => (
            <div
              key={item.rank}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
              }}
            >
              <div
                className={`bg-${item.bootstrapClass}`}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              ></div>
              <span>{item.rank}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    const SSE_ENDPOINT = "/api/tuffstandings/sse/tuffstandings";
    let isActive = true;
    let retryCount = 0;
    const maxRetries = 5;

    const setupSSE = async () => {
      try {
        console.log("Setting up SSE connection...");
        const accessToken = await getAccessTokenSilently();
        SSEManager.setAccessToken(accessToken);
        await fetchSeasonData();
        SSEManager.addListener(SSE_ENDPOINT, handleSSEMessage);
        console.log("SSE connection established");
        retryCount = 0;
      } catch (err) {
        console.error("Error setting up SSE or fetching initial data:", err);
        setError(`Error: ${err.message}`);

        if (isActive && retryCount < maxRetries) {
          retryCount++;
          console.log(
            `Retrying SSE connection (${retryCount}/${maxRetries})...`
          );
          setTimeout(setupSSE, 5000);
        }
      }
    };

    setupSSE();

    return () => {
      isActive = false;
      console.log("Cleaning up SSE connection");
      SSEManager.removeListener(SSE_ENDPOINT, handleSSEMessage);
    };
  }, [fetchSeasonData, getAccessTokenSilently, handleSSEMessage]);

  useEffect(() => {
    if (seasonData.regularSeason.length === 0 || isProcessing) return;

    setIsTableLoading(true);

    try {
      if (
        currentNFLWeek > regularSeasonWeeks &&
        seasonData.playoffs.length > 0
      ) {
        renderTable(seasonData.playoffs, "Playoffs", true);
      }
      renderTable(seasonData.regularSeason, "Regular Season", false);
    } finally {
      setIsTableLoading(false);
    }
  }, [
    seasonData,
    currentNFLWeek,
    renderTable,
    isProcessing,
    regularSeasonWeeks,
  ]);

  if (loading || isProcessing) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      <br />
      <h4 style={{ fontSize: headerFontSize }}>2024 TUFF Standings</h4>
      <br />

      {error && <Alert variant="danger">{error}</Alert>}

      {!error && seasonData.regularSeason.length === 0 && (
        <Alert variant="info">No data available for the season.</Alert>
      )}

      {!error && seasonData.regularSeason.length > 0 && (
        <>
          {isTableLoading ? (
            <div className="text-center my-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading standings...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {currentNFLWeek > regularSeasonWeeks &&
                seasonData.playoffs.length > 0 &&
                renderTable(seasonData.playoffs, "Playoffs", true)}
              {renderTable(seasonData.regularSeason, "Regular Season", false)}
              <div className="d-flex justify-content-center">
                {renderLegend()}
              </div>
              <br />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default TUFFStandingsPage;
