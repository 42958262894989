import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getProtectedResource } from "../services/nfl-news.service";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTheme } from "../contexts/ThemeContext";

function getVariation(nflNewsComponent, nflNews, fontSize) {
  if (nflNewsComponent === "enabled") {
    return nflNews.length > 0 ? (
      nflNews.map((nflnewsItem) => (
        <tr key={nflnewsItem.id}>
          <td>
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                fontSize: `${fontSize}px`,
                color: "inherit",
              }}
            >
              {nflnewsItem.image && (
                <img
                  src={nflnewsItem.image}
                  alt={nflnewsItem.title}
                  style={{
                    width: "auto",
                    height: "40px",
                  }}
                />
              )}
            </a>
          </td>
          <td className="text-start">
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                fontSize: `${fontSize}px`,
                color: "inherit",
              }}
            >
              {nflnewsItem.title}
            </a>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>Loading news...</td>
      </tr>
    );
  } else if (nflNewsComponent === "disabled") {
    return (
      <tr>
        <td>
          <h3>NFL News is not enabled</h3>
        </td>
      </tr>
    );
  } else if (nflNewsComponent === "restricted") {
    return (
      <tr>
        <td>
          NFL News is only available to TUFF Premium Members. Upgrade your TUFF
          subsctipion to Premium to access NFL News.
        </td>
      </tr>
    );
  }
}

export const NFLNewsPage = withLDConsumer()(({ flags, fontSize }) => {
  const [nflNews, setNFLNews] = useState([]);
  const variation = getVariation(flags.nflNewsComponent, nflNews, fontSize);
  const { theme } = useTheme();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getProtectedResource(accessToken);
        setNFLNews(response.data);
      } catch (err) {
        if (flags?.consoleTrace) {
          console.trace(err);
        } else {
          console.log(err);
        }
      }
    };

    fetchData();
  }, [flags, getAccessTokenSilently]);

  return (
    <div>
        <h4>NFL News</h4>
      <Table hover size="sm" variant={theme}>
        <tbody>{variation}</tbody>
      </Table>
    </div>
  );
});
