import { callExternalApi } from './external-api.service';
import { compareWeeks } from "../components/WeekUtils";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getTradeCount = async (accessToken, email, startWeek, endWeek, tradeType) => {
  console.log(`Fetching ${tradeType} trade count for ${email} from ${startWeek} to ${endWeek}`);

  // Check if the current week is before Week 2
  if (compareWeeks(endWeek, "Week 2") < 0) {
    console.log("Current week is before Week 2, trades haven't started yet");
    return { data: "not-started", error: null };
  }

  // Validate week format
  const weekRegex = /^Week \d+$/;
  if (!weekRegex.test(startWeek) || !weekRegex.test(endWeek)) {
    console.error("Invalid week format");
    return { 
      data: null, 
      error: "Weeks must be in format 'Week X' where X is a number" 
    };
  }

  // Validate week range
  const startWeekNum = parseInt(startWeek.split(" ")[1]);
  const endWeekNum = parseInt(endWeek.split(" ")[1]);
  if (startWeekNum > endWeekNum) {
    console.error("Invalid week range");
    return {
      data: null,
      error: "Start week cannot be after end week"
    };
  }

  const config = {
    url: `${apiServerUrl}/api/trades/count`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      email,
      startWeek,
      endWeek,
      tradeType: tradeType.toLowerCase()
    },
  };

  console.log("API request config:", JSON.stringify(config, null, 2));

  try {
    const response = await callExternalApi({ config });
    console.log("Raw API response:", response);

    // Check for error in response
    if (response.error) {
      console.error("Error fetching trade count:", response.error);
      return { data: null, error: response.error };
    }

    // Check for valid response data structure
    if (response.data && response.data.data) {
      const tradeData = response.data.data;
      console.log(`Received trade count: ${tradeData.count}`);
      
      // Return the count and additional metadata
      return { 
        data: tradeData.count, 
        periodComplete: tradeData.periodComplete,
        error: null 
      };
    }

    console.error("Unexpected response format:", response);
    return { 
      data: null, 
      error: "Unexpected response format" 
    };

  } catch (error) {
    console.error("Unexpected error in getTradeCount:", error);
    return { 
      data: null, 
      error: error.message || "An unexpected error occurred" 
    };
  }
};