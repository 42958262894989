import React, { useState, useEffect, useCallback } from "react";
import { Container, Alert, Spinner, Form, Table } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { getGameWeeks, getCurrentWeek } from "../services/schedule.service";
import {
  getFantasyPoints,
  getTeamNames,
} from "../services/fantasyPoints.service";
import { getRoster } from "../services/roster.service";
import { compareWeeks, sortWeeks } from "../components/WeekUtils";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { useParams } from "react-router-dom";

const positionOrder = [
  "QB1",
  "QB2",
  "RB1",
  "RB2",
  "RB3",
  "WR1",
  "WR2",
  "WR3",
  "TE1",
  "FLEX1",
  "FLEX2",
];

export const FantasyPointsPage = () => {
  const { team } = useParams();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently, user } = useAuth0();
  const [fantasyPointsData, setFantasyPointsData] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [currentWeek, setCurrentWeek] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalRosterPoints, setTotalRosterPoints] = useState(0);
  const [teamNames, setTeamNames] = useState({});
  const [selectedTeam, setSelectedTeam] = useState("");

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  const findUserTeam = useCallback((teamNamesData, userEmail) => {
    return (
      Object.entries(teamNamesData).find(
        ([_, email]) => email === userEmail
      )?.[0] || null
    );
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const [weeksResponse, currentWeekResponse, teamNamesResponse] =
          await Promise.all([
            getGameWeeks(accessToken),
            getCurrentWeek(accessToken),
            getTeamNames(accessToken),
          ]);

        if (weeksResponse.error) throw new Error(weeksResponse.error);
        if (currentWeekResponse.error)
          throw new Error(currentWeekResponse.error);
        if (teamNamesResponse.error) throw new Error(teamNamesResponse.error);

        const currentWeekData = currentWeekResponse.data;
        setCurrentWeek(currentWeekData);

        // Filter weeks to include only Week 1 through Week 17
        const filteredWeeks = weeksResponse.data
          .filter((week) => {
            const weekNumber = parseInt(week.replace("Week ", ""));
            return weekNumber >= 1 && weekNumber <= 17;
          })
          .filter((week) => compareWeeks(week, currentWeekData) <= 0);

        const sortedWeeks = sortWeeks(filteredWeeks);
        setAvailableWeeks(sortedWeeks);

        // Set selected week to current week or the last available week if current week is beyond Week 17
        const selectedWeekData = sortedWeeks.includes(currentWeekData)
          ? currentWeekData
          : sortedWeeks[sortedWeeks.length - 1];
        setSelectedWeek(selectedWeekData);

        setTeamNames(teamNamesResponse.data);

        let initialTeam;
        if (team) {
          initialTeam = decodeURIComponent(team);
        } else {
          const userTeam = findUserTeam(teamNamesResponse.data, user.email);
          initialTeam = userTeam || Object.keys(teamNamesResponse.data)[0];
        }
        setSelectedTeam(initialTeam);

        await fetchFantasyPointsData(
          accessToken,
          teamNamesResponse.data[initialTeam],
          selectedWeekData
        );
      } catch (err) {
        console.error("Error in fetchInitialData:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [getAccessTokenSilently, user.email, team, findUserTeam]);

  const fetchFantasyPointsData = async (accessToken, email, week) => {
    try {
      const [rosterResponse, fantasyPointsResponse] = await Promise.all([
        getRoster(accessToken, email, week),
        getFantasyPoints(accessToken, email, week),
      ]);

      if (rosterResponse.error) throw new Error(rosterResponse.error);
      if (fantasyPointsResponse.error)
        throw new Error(fantasyPointsResponse.error);

      const data = positionOrder.map((position) => {
        const player = rosterResponse.roster.find(
          (p) => p.position === position
        );
        if (!player)
          return { position, playerName: "Empty Slot", totalFantasyPoints: 0 };

        const fantasyPoints = fantasyPointsResponse.fantasyPoints.find(
          (fp) => fp.playerID === player.playerID
        );
        return {
          position,
          playerName: player.playerName || "Unknown Player",
          originalPosition:
            player.originalPosition ||
            player.position.replace(/[0-9]/g, "") ||
            "Unknown",
          teamAbv: player.teamAbv || "N/A",
          totalFantasyPoints:
            fantasyPoints && fantasyPoints.totalFantasyPoints != null
              ? Math.round(Number(fantasyPoints.totalFantasyPoints))
              : 0,
        };
      });

      setFantasyPointsData(data);
      const total = data.reduce(
        (sum, player) => sum + player.totalFantasyPoints,
        0
      );
      setTotalRosterPoints(total);
    } catch (err) {
      console.error("Error fetching fantasy points:", err);
      setError(
        `Error fetching fantasy points for week ${week}: ${err.message}`
      );
      setFantasyPointsData([]);
      setTotalRosterPoints(0);
    }
  };

  const handleWeekChange = useCallback(
    async (newWeek) => {
      setSelectedWeek(newWeek);
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await fetchFantasyPointsData(
          accessToken,
          teamNames[selectedTeam],
          newWeek
        );
      } catch (err) {
        console.error("Error in handleWeekChange:", err);
        setError(
          `Error fetching fantasy points for week ${newWeek}: ${err.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, selectedTeam, teamNames]
  );

  const handleTeamChange = useCallback(
    async (newTeam) => {
      setSelectedTeam(newTeam);
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await fetchFantasyPointsData(
          accessToken,
          teamNames[newTeam],
          selectedWeek
        );
      } catch (err) {
        console.error("Error in handleTeamChange:", err);
        setError(
          `Error fetching fantasy points for team ${newTeam}: ${err.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, selectedWeek, teamNames]
  );

  const renderFantasyPointsTable = useCallback(() => {
    return (
      <div className="fantasy-points-table-container">
        <Table
          striped
          bordered
          hover
          size="sm"
          variant={theme}
          className="align-middle"
        >
          <thead>
            <tr>
              <th className="fantasy-position-column">Position</th>
              <th className="fantasy-player-column">Player</th>
              <th className="fantasy-team-column">Team</th>
              <th className="fantasy-points-column">Total Fantasy Points</th>
            </tr>
          </thead>
          <tbody>
            {fantasyPointsData.map((player, index) => (
              <tr key={index}>
                <td className="fantasy-position-column">{player.position}</td>
                <td className="fantasy-player-column">{player.playerName}</td>
                <td className="fantasy-team-column">{player.teamAbv}</td>
                <td className="fantasy-points-column">
                  {player.totalFantasyPoints}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="fantasy-points-total">
          <h4>Total Roster Fantasy Points: {totalRosterPoints}</h4>
        </div>
      </div>
    );
  }, [fantasyPointsData, theme, totalRosterPoints]);

  if (loading) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      <div className="fantasy-points-wrapper">
        <br />
        <h4 style={{ fontSize: headerFontSize }}>Weekly Fantasy Points</h4>
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="fantasy-points-content">
          <div className="fantasy-points-form-container">
            <Form.Group className="mb-3">
              <Form.Label>Select Team:</Form.Label>
              <Form.Select
                value={selectedTeam}
                onChange={(e) => handleTeamChange(e.target.value)}
              >
                {Object.keys(teamNames).map((teamName) => (
                  <option key={teamName} value={teamName}>
                    {teamName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Week:</Form.Label>
              <Form.Select
                value={selectedWeek}
                onChange={(e) => handleWeekChange(e.target.value)}
              >
                {availableWeeks.map((week) => (
                  <option key={week} value={week}>
                    {week === currentWeek ? `${week} (Current)` : week}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          {renderFantasyPointsTable()}
        </div>
      </div>
    </Container>
  );
};

export default FantasyPointsPage;
