import React, { useState } from 'react';
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Marquee from "react-fast-marquee";
import { NFLNewsFooter } from "./NFLNewsFooter";
import { Container, Row, Col, Form } from 'react-bootstrap';

export const PageFooter = withLDConsumer()(({ flags, fontSize }) => {
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(true);

  const toggleMarqueeVisibility = () => {
    setIsMarqueeVisible(prev => !prev);
  };

  const footerStyle = {
    fontSize: `${fontSize}px`,
    height: '60px',
    overflow: 'hidden'
  };

  const contentStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  };

  const toggleColumnStyle = {
    width: '150px', // Fixed width for the toggle column
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  };

  return (
    <footer className="footer page-footer" style={footerStyle}>
      <Container fluid style={contentStyle}>
        <Row className="align-items-center w-100">
          <Col style={{ overflow: 'hidden' }}>
            {flags.footerComponent && isMarqueeVisible ? (
              <Marquee gradientWidth={0}>
                <NFLNewsFooter />
              </Marquee>
            ) : (
              <p className="m-0">{flags.footerComponent ? "" : "Footer content is unavailable"}</p>
            )}
          </Col>
          <Col xs="auto" style={toggleColumnStyle}>
            <Form.Label className="me-2 mb-0">NFL News</Form.Label>
            <Form.Check
              type="switch"
              id="nfl-news-toggle"
              checked={isMarqueeVisible}
              onChange={toggleMarqueeVisibility}
              disabled={!flags.footerComponent}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
});