import React from 'react';
import { HomePageCard } from "../components/HomePageCard";
import backgroundImage from '../assets/img/ppe.png';

export const HomePage = () => {
  return (
    <div className="Home-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <HomePageCard />
    </div>
  );
};