import React, { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { NavBar } from "./NavBar";
import { PageFooter } from "./PageFooter";

const MemoizedNavBar = memo(NavBar);
const MemoizedPageFooter = memo(PageFooter);

export const PageLayout = ({ children }) => {
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const memoizedNavBar = useMemo(() => <MemoizedNavBar />, []);
  const memoizedFooter = useMemo(() => <MemoizedPageFooter fontSize={fontSize} />, [fontSize]);

  const layoutContext = useMemo(() => ({
    theme,
    fontSize,
  }), [theme, fontSize]);

  return (
    <div className={`page-layout ${isHomePage ? 'home-page' : ''}`} id={theme}>
      {!isHomePage && <header className="page-layout__header">{memoizedNavBar}</header>}
      <main className="page-layout__content" style={{ fontSize: `${fontSize}px` }}>
        {React.Children.map(children, child =>
          React.isValidElement(child)
            ? React.cloneElement(child, layoutContext)
            : child
        )}
      </main>
      {!isHomePage && <footer className="page-layout__footer">{memoizedFooter}</footer>}
    </div>
  );
};

export const MemoizedPageLayout = memo(PageLayout);