import { useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getTradeCount } from "../services/trades.service";

export const useTradeData = (selectedTeam, selectedWeek) => {
  const { getAccessTokenSilently } = useAuth0();
  const [regularTradeCount, setRegularTradeCount] = useState(0);
  const [regularTradeLimit, setRegularTradeLimit] = useState(0);
  const [irTradeCount, setIRTradeCount] = useState(0);
  const [irTradeLimit, setIRTradeLimit] = useState(0);
  const [tradeError, setTradeError] = useState(null);

  const fetchTradeCounts = useCallback(async () => {
    if (!selectedTeam || !selectedWeek) return;

    try {
      const accessToken = await getAccessTokenSilently();
      const [regularCountResponse, irCountResponse] = await Promise.all([
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "regular"),
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "ir")
      ]);

      console.log('Regular Count Response Data:', regularCountResponse.data);
      console.log('IR Count Response Data:', irCountResponse.data);

      if (regularCountResponse.error || irCountResponse.error) {
        setTradeError("There was an issue fetching trade counts. Some data may be unavailable.");
        return;
      }

      // Fix: The count is directly in data
      setRegularTradeCount(regularCountResponse.data);
      setIRTradeCount(irCountResponse.data);

      // Set limits based on week
      const weekNumber = parseInt(selectedWeek.split(" ")[1]);
      setRegularTradeLimit(weekNumber >= 9 ? 11 : 10);
      setIRTradeLimit(2);
      setTradeError(null);

    } catch (error) {
      console.error("Error fetching trade counts:", error);
      setTradeError("Failed to load trade information");
    }
  }, [selectedTeam, selectedWeek, getAccessTokenSilently]);

  return {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit,
    tradeError,
    fetchTradeCounts
  };
};