import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Container,
  Form,
  Table,
  Alert,
  Spinner,
  Button,
} from "react-bootstrap";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useCommissionerCheck } from "../hooks/useCommissionerCheck";
import { getAllRosters } from "../services/roster.service";
import { updatePlayerInRoster } from "../services/commissioner.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { CapAndTradeInfo } from "../components/CapAndTradeInfo";
import { TradeManagement } from "../components/TradeManagement";
import EditPlayerModal from "../components/EditPlayerModal";

export const RosterManagementPage = withLDConsumer()(() => {
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently, user } = useAuth0();
  const {
    isCommissioner,
    loading: isCommissionerLoading,
    error: commissionerError,
  } = useCommissionerCheck();

  const capAndTradeRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rosters, setRosters] = useState({});
  const [teams, setTeams] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentWeek, setCurrentWeek] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedTeamName, setSelectedTeamName] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [roster, setRoster] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPosition, setEditingPosition] = useState("");
  const [editingPlayer, setEditingPlayer] = useState(null);
  const [remainingTrades, setRemainingTrades] = useState({
    regular: 0,
    ir: 0,
  });
  const [capInfo, setCapInfo] = useState({
    currentCap: 0,
    availableCap: 0,
    salaryCap: 0,
  });

  const POSITION_MAP = useMemo(
    () => ({
      QB1: "QB",
      QB2: "QB",
      RB1: "RB",
      RB2: "RB",
      RB3: "RB",
      WR1: "WR",
      WR2: "WR",
      WR3: "WR",
      TE1: "TE",
      FLEX1: "FLEX",
      FLEX2: "FLEX",
    }),
    []
  );

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  const formatCurrency = useCallback((value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }, []);

  const fetchAllRosters = useCallback(
    async (keepSelectedTeam = false) => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await getAllRosters(token);
        setRosters(response.data);
        setCurrentWeek(response.currentWeek);

        const teamsMap = Object.entries(response.data).map(
          ([email, teamData]) => ({
            email,
            teamName: teamData.tuff_team_name || "Unknown Team",
          })
        );

        const sortedTeams = teamsMap.sort((a, b) =>
          a.teamName.localeCompare(b.teamName)
        );

        setTeams(sortedTeams);

        if (sortedTeams.length > 0) {
          const firstTeamEmail = sortedTeams[0].email;
          const firstTeamWeeks = Object.keys(
            response.data[firstTeamEmail].weeks || {}
          );
          setWeeks(firstTeamWeeks);

          if (!keepSelectedTeam) {
            const userTeam = sortedTeams.find(
              (team) => team.email === user.email
            );
            if (userTeam) {
              setSelectedTeam(userTeam.email);
              setSelectedTeamName(userTeam.teamName);
            } else {
              setSelectedTeam(sortedTeams[0].email);
              setSelectedTeamName(sortedTeams[0].teamName);
            }
          }

          if (
            response.currentWeek &&
            firstTeamWeeks.includes(response.currentWeek)
          ) {
            setSelectedWeek(response.currentWeek);
          } else if (firstTeamWeeks.length > 0) {
            setSelectedWeek(firstTeamWeeks[firstTeamWeeks.length - 1]);
          }
        }
      } catch (error) {
        setError("Failed to load rosters");
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, user.email]
  );

  useEffect(() => {
    fetchAllRosters();
  }, [fetchAllRosters]);

  useEffect(() => {
    if (selectedTeam && selectedWeek && rosters[selectedTeam]) {
      const weekData = rosters[selectedTeam].weeks[selectedWeek] || [];
      setRoster(weekData);
    }
  }, [selectedTeam, selectedWeek, rosters]);

  const handleTeamChange = useCallback(
    (e) => {
      const email = e.target.value;
      setSelectedTeam(email);
      const team = teams.find((t) => t.email === email);
      setSelectedTeamName(team ? team.teamName : "");
    },
    [teams]
  );

  const handleWeekChange = useCallback((e) => {
    setSelectedWeek(e.target.value);
  }, []);

  const isPlayerOnOtherRoster = useCallback(
    (playerID) => {
      // Get the previous week number
      const weekIndex = weeks.indexOf(selectedWeek);
      const previousWeek = weekIndex > 0 ? weeks[weekIndex - 1] : null;

      if (!previousWeek) return false;

      // Check all teams' rosters from the previous week
      return Object.entries(rosters).some(([email, teamData]) => {
        if (email === selectedTeam) return false; // Skip current team

        const previousWeekRoster = teamData.weeks[previousWeek] || [];
        return previousWeekRoster.some(
          (player) => player.playerID === playerID
        );
      });
    },
    [weeks, selectedWeek, rosters, selectedTeam]
  );

  // Modified handleEditPlayer to fetch position-specific players
  const handleEditPlayer = useCallback((player) => {
    if (!player.playerID) {
      setError("Cannot edit player: Missing player ID");
      return;
    }

    setEditingPosition(player.position);
    setEditingPlayer(player);
    setShowEditModal(true);
  }, []);

  const handleSavePlayer = useCallback(
    async (newPlayer) => {
      if (!newPlayer) {
        setError("Please select a player before saving.");
        return;
      }

      if (!editingPlayer || !editingPlayer.playerID) {
        setError("An error occurred. Please try again.");
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const rosterData = {
          email: selectedTeam,
          week: currentWeek,
          oldPlayerID: editingPlayer.playerID,
          newPlayerID: newPlayer.playerID,
        };
        await updatePlayerInRoster(token, rosterData);
        setShowEditModal(false);
        await fetchAllRosters(true); // Pass true to keep the selected team
      } catch (error) {
        setError("Failed to update roster. Please try again.");
      }
    },
    [
      getAccessTokenSilently,
      selectedTeam,
      currentWeek,
      editingPlayer,
      fetchAllRosters,
    ]
  );

  const handleTradeInfoUpdate = useCallback((info) => {
    console.log("Raw info from CapAndTradeInfo:", info);
    setRemainingTrades({
      regular: info.regular,
      ir: info.ir,
    });

    setCapInfo({
      currentCap: info.currentCap || 0,
      availableCap: info.availableCap || 0,
      salaryCap: info.salaryCap || 0,
    });
  }, []);

  // Use the effect to monitor changes instead
  useEffect(() => {
    console.log(
      "RosterManagementPage - remainingTrades changed:",
      remainingTrades
    );
  }, [remainingTrades]);

  const handleTradeUpdate = useCallback(async () => {
    if (capAndTradeRef.current) {
      await capAndTradeRef.current.fetchTradeCounts();
    }
  }, []);

  const renderRosterTable = useCallback(() => {
    const showEditColumn = isCommissioner && selectedWeek === currentWeek;

    return (
      <div className="roster-management-table-container">
        <Table
          bordered
          striped
          hover
          size="sm"
          variant={theme}
          className="align-middle"
        >
          <thead>
            <tr>
              <th className="management-position-column">Position</th>
              <th className="management-player-column">Player Name</th>
              <th className="management-team-column">Team</th>
              <th className="management-salary-column">Salary</th>
              {showEditColumn && (
                <th className="management-actions-column">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {roster.length > 0 ? (
              roster.map((player, idx) => (
                <tr key={idx}>
                  <td className="management-position-column">
                    {player.position}
                  </td>
                  <td className="management-player-column">
                    {player.player_name}
                  </td>
                  <td className="management-team-column">{player.teamAbv}</td>
                  <td className="management-salary-column">
                    {formatCurrency(player.salary)}
                  </td>
                  {showEditColumn && (
                    <td className="management-actions-column">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditPlayer(player)}
                      >
                        Edit Player
                      </Button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={showEditColumn ? 5 : 4} className="text-center">
                  No players found for selected team and week.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }, [
    roster,
    theme,
    isCommissioner,
    formatCurrency,
    handleEditPlayer,
    selectedWeek,
    currentWeek,
  ]);

  if (commissionerError || (!isCommissioner && !isCommissionerLoading)) {
    return <Navigate to="/" replace />;
  }

  if (loading) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid>
      <div className="roster-management-wrapper">
        <br />
        <h4 style={{ fontSize: headerFontSize }}>
          TUFF Team Roster Management
        </h4>
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="roster-management-content">
          <div className="roster-management-form-container">
            <Form.Group className="mb-3">
              <Form.Label>Select Team:</Form.Label>
              <Form.Select value={selectedTeam} onChange={handleTeamChange}>
                <option value="">Choose...</option>
                {teams.map(({ email, teamName }) => (
                  <option key={email} value={email}>
                    {teamName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Week:</Form.Label>
              <Form.Select value={selectedWeek} onChange={handleWeekChange}>
                <option value="">Choose...</option>
                {weeks.map((week) => (
                  <option key={week} value={week}>
                    {week === currentWeek ? `${week} (Current)` : week}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>

          {selectedTeam && selectedWeek && (
            <>
              <CapAndTradeInfo
                ref={capAndTradeRef}
                teamRoster={roster}
                selectedTeam={selectedTeam}
                currentWeek={currentWeek}
                selectedWeek={selectedWeek}
                onTradeInfoUpdate={handleTradeInfoUpdate}
              />
              <br />
              {renderRosterTable()}
              <br />
              {/* Only render TradeManagement if we have a selected team and week */}
              {selectedTeam && selectedWeek && (
                <TradeManagement
                  selectedTeam={selectedTeam}
                  selectedTeamName={selectedTeamName}
                  theme={theme}
                  fontSize={fontSize}
                  currentWeek={currentWeek}
                  selectedWeek={selectedWeek}
                  weeks={weeks}
                  remainingTrades={remainingTrades}
                  onTradeUpdate={handleTradeUpdate}
                  teamRoster={roster}
                  capInfo={capInfo} // Pass the state directly
                  formatCurrency={formatCurrency}
                />
              )}
            </>
          )}
        </div>
      </div>
      <EditPlayerModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        editingPlayer={editingPlayer}
        editingPosition={editingPosition}
        POSITION_MAP={POSITION_MAP}
        selectedWeek={selectedWeek}
        getAccessTokenSilently={getAccessTokenSilently}
        isPlayerOnOtherRoster={isPlayerOnOtherRoster}
        onSavePlayer={handleSavePlayer}
        formatCurrency={formatCurrency}
        roster={roster}
        salaryCap={capInfo.salaryCap}
        weeks={weeks}
        rosters={rosters}
        selectedTeam={selectedTeam}
      />
    </Container>
  );
});

export default RosterManagementPage;
