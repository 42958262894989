import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../auth/logout";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { callExternalApi } from "../services/external-api.service";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { BsDashCircle, BsPlusCircle } from "react-icons/bs";

const CURRENT_SEASON = process.env.REACT_APP_CURRENT_SEASON;

const FontSizeControls = () => {
  const { increaseFontSize, decreaseFontSize } = useFontSize();

  const buttonStyle = {
    color: "white",
    backgroundColor: "transparent",
    border: "none",
    padding: "2px",
    marginLeft: "5px",
  };

  return (
    <div>
      <Button
        style={buttonStyle}
        onClick={increaseFontSize}
        onMouseOver={(e) => (e.currentTarget.style.opacity = "0.7")}
        onMouseOut={(e) => (e.currentTarget.style.opacity = "1")}
      >
        <BsPlusCircle size={16} />
      </Button>
      <Button
        style={buttonStyle}
        onClick={decreaseFontSize}
        onMouseOver={(e) => (e.currentTarget.style.opacity = "0.7")}
        onMouseOut={(e) => (e.currentTarget.style.opacity = "1")}
      >
        <BsDashCircle size={16} />
      </Button>
    </div>
  );
};

export const NavBar = withLDConsumer()(({ flags }) => {
  const [show, setShow] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } =
    useAuth0();
  const [isCommissioner, setIsCommissioner] = useState(false);

  const canvasClose = () => setShow(false);
  const canvasShow = () => setShow(true);

  const checkCommissionerStatus = useCallback(async () => {
    if (isAuthenticated && !isLoading && user) {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          url: `${process.env.REACT_APP_API_SERVER_URL}/api/commissioner/check-commissioner`,
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const { data, error } = await callExternalApi({ config });
        if (error) throw new Error(error.message || "Unknown error occurred");
        setIsCommissioner(data.isCommissioner);
      } catch (error) {
        console.error("Error checking commissioner status:", error);
        setIsCommissioner(false);
      }
    }
  }, [isAuthenticated, isLoading, user, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      checkCommissionerStatus();
    }
  }, [isAuthenticated, isLoading, checkCommissionerStatus]);

  if (!flags.navbar) {
    return null;
  }

  return (
    <Navbar
      expand={false}
      bg={theme === "dark" ? "primary" : "primary"}
      variant="dark"
      sticky="top"
    >
      <Container fluid>
        {flags.navbarBrand && (
          <Navbar.Brand as={Link} to="/dashboard">
            TUFF Fantasy Football {CURRENT_SEASON}
          </Navbar.Brand>
        )}
        {flags.themeMode && (
          <Form.Switch
            className={`ms-auto px-10 ${
              theme === "dark" ? "dark-mode-switch" : ""
            }`}
            type="switch"
            id="mode-switch"
            reverse={true}
            onChange={toggleTheme}
            checked={theme === "dark"}
          />
        )}
        <div style={{ margin: "0 10px 0 5px" }}>
          <FontSizeControls />
        </div>

        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={canvasShow} />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement={flags.navbarOffcanvasPlacement}
          show={show}
          onHide={canvasClose}
          data-bs-theme={theme}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              TUFF Fantasy Football {CURRENT_SEASON}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {flags.dashboardComponent ? (
                <Nav.Link as={Link} to="/dashboard" onClick={canvasClose}>
                  TUFF Dashboard
                </Nav.Link>
              ) : (
                <Nav.Link
                  onClick={() => alert("Dashboard is currently unavailable")}
                >
                  TUFF Dashboard
                </Nav.Link>
              )}
              {flags.standingsComponent ? (
                <Nav.Link as={Link} to="/tuffstandings" onClick={canvasClose}>
                  TUFF Standings
                </Nav.Link>
              ) : (
                <Nav.Link
                  onClick={() =>
                    alert("TUFF Standings are currently unavailable")
                  }
                >
                  TUFF Standings
                </Nav.Link>
              )}
              <Nav.Link as={Link} to="/roster" onClick={canvasClose}>
                TUFF Team Rosters
              </Nav.Link>
              <Nav.Link as={Link} to="/fantasypoints" onClick={canvasClose}>
                TUFF Team Weekly Fantasy Points
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/playerfantasypoints"
                onClick={canvasClose}
              >
                Player Fantasy Points
              </Nav.Link>
              {/*<Nav.Link as={Link} to="/weeklyplayerstats" onClick={canvasClose}>
                Weekly Player Stats & Fantasy Points
              </Nav.Link>*/}
              {flags.premiumContent && (
                <NavDropdown
                  title="Premium Content"
                  id="offcanvasNavbarDropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/nflnews"
                    onClick={canvasClose}
                  >
                    NFL News
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {/*<Nav.Link as={Link} to="/profile" onClick={canvasClose}>
                Profile
              </Nav.Link>*/}
              {isCommissioner && (
                <NavDropdown
                  title="Commissioner Tools"
                  id="offcanvasNavbarDropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/commissioner/rostermanagement"
                    onClick={canvasClose}
                  >
                    TUFF Team Roster Management
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              <LogoutButton />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
});
